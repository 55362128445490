import { Grid } from "@mui/material";
import { MDButton } from "components/MDButton";
import { countries } from "layouts/dispatch/create/country-list";
import { useEffect, useState } from "react";
import { findWebpush } from "repositories/webpush-repository";

export function SendWebpushTriggerEditor({ data, onCancel, onSave }) {
  const [countryCode, setCountryCode] = useState(data.countryCode || "US");
  const [webpushId, setWebpushId] = useState(null);
  const [webpushList, setWebpushList] = useState([]);

  useEffect(() => {
    findWebpush().then((result) => {
      if (result.data[0].length > 0) {
        setWebpushList(result.data[0]);
      }
    });
  }, []);

  function onCreate() {
    onSave({
      countryCode,
      webpushId,
    });
  }

  return (
    <div>
      <div>Select a country</div>
      <select
        value={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        placeholder="Select a country"
      >
        {Object.keys(countries).map((letter) => (
          <option key={letter} value={letter}>
            {countries[letter]}
          </option>
        ))}
      </select>
      <div>Select a webpush</div>
      <select
        value={webpushId}
        onChange={(e) => setWebpushId(e.target.value)}
        placeholder="Select a webpush"
      >
        <option value={null}>All webpush</option>
        {webpushList.map((item) => (
          <option key={item._id} value={item._id}>
            {item.name}
          </option>
        ))}
      </select>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="white"
            fullWidth
            onClick={onCancel}
          >
            Cancel
          </MDButton>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={onCreate}
          >
            Create
          </MDButton>
        </Grid>
      </Grid>
    </div>
  );
}
