import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "sms";

export async function createSms(companyId, name, description, body) {
  return client.post(path, {
    companyId,
    name,
    description,
    body,
  });
}

export async function updateSms(id, name, description, body) {
  return client.patch(`${path}/${id}`, {
    name,
    description,
    body,
  });
}

export const findSms = cache("findSms", (name) =>
  client.get(path, {
    params: {
      companyId: localStorage.getItem("companyId"),
      name,
    },
  })
);

export function deleteSms(smsId) {
  return client.delete(`${path}/${smsId}`);
}

export const findSmsById = cache("findEmailById", (emailId) =>
  client.get(`${path}/${emailId}`)
);
