import { Icon } from "@mui/material";
import { DeleteButton } from "./delete-button.component";

export function WebpushSubscribeTrigger({ style, onDelete }) {
  return (
    <button type="button" style={style}>
      <div>
        <Icon style={{ paddingTop: "4px", marginRight: "4px" }}>
          notification
        </Icon>
        <span>Webpush Subscribe Trigger</span>
        <br />
        <span style={{ fontSize: "0.8rem" }}>
          Webpush subscription added to platform
        </span>
      </div>
      <DeleteButton onDelete={onDelete} />
    </button>
  );
}
