import { client } from "./client";

const path = "dispatches";

export async function createDispatch(
  companyId,
  description,
  type,
  parameters,
  runAt
) {
  return client.post(path, {
    companyId,
    description,
    type,
    parameters,
    runAt,
  });
}

export function findDispatches(filter, skip, limit) {
  return client.get(path, {
    params: {
      filter: JSON.stringify({
        ...filter,
        companyId: localStorage.getItem("companyId"),
      }),
      sort: JSON.stringify({
        createdAt: -1,
      }),
      skip,
      limit,
    },
  });
}

export function deleteDispatchById(id) {
  return client.delete(`${path}/${id}`);
}

export function findDispatchById(dispatchId) {
  return client.get(`${path}/${dispatchId}`);
}
