export const IntegrationType = {
  EMAIL: 0,
  SMS: 1,
  EMAIL_VERIFICATION: 2,
};

export const ServiceType = {
  AWS_EMAIL: 0,
  CONTELE: 1,
  QUICK_EMAIL: 2,
  SENDGRID: 3,
  SENDBLUE: 4,
};
