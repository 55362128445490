import { Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { findWebpushMessageById } from "repositories/webpush-message-repository";
import { DeleteButton } from "./delete-button.component";

export function SendWebpush({ style, data, onEdit, onDelete }) {
  const [webpushMessage, setWebpushMessage] = useState(null);

  useEffect(() => {
    findWebpushMessageById(data.webpushMessageId).then((result) =>
      setWebpushMessage(result.data)
    );
  }, [data]);

  return (
    <div style={style}>
      <div>
        <Icon style={{ paddingTop: "4px", marginRight: "4px" }}>
          notification
        </Icon>
        Send Webpush "{webpushMessage?.name}"
      </div>
      <DeleteButton onEdit={onEdit} onDelete={onDelete} />
    </div>
  );
}
