import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "dashboard";

export const dashboardTotalContacts = cache("dashboardTotalContacts", () =>
  client.get(`${path}/contacts/total`, {
    params: {
      companyId: localStorage.getItem("companyId"),
    },
  })
);

export const dashboardContacts = cache("dashboardContacts", (days) =>
  client.get(`${path}/contacts`, {
    params: {
      companyId: localStorage.getItem("companyId"),
      days,
    },
  })
);
