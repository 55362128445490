import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { MDButton } from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { FormValidationError } from "layouts/authentication/components/form-validation-error";
import { useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import {
  createWebpushMessage,
  findWebpushMessageById,
  updateWebpushMessage,
} from "repositories/webpush-message-repository";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { ViewWebpushMessageDetailsComponent } from "./view-details";

export function WebpushMessageCreate() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(null);
  const [link, setLink] = useState(null);
  const [name, setName] = useState(null);
  const [title, setTitle] = useState(null);
  const [icon, setIcon] = useState(null);
  const [image, setImage] = useState(null);
  const [body, setBody] = useState(null);
  const [vibrate, setVibrate] = useState(true);
  const [dir, setDir] = useState("auto");
  const [actions, setActions] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (id != null)
      findWebpushMessageById(id).then(({ data }) => {
        setName(data.name);
        setTitle(data.payload.title);
        setLink(data.payload.link || "");
        setIcon(data.payload.icon);
        setImage(data.payload.image);
        setBody(data.payload.body);
        setVibrate(data.payload.vibrate);
        setDir(data.payload.dir);
        setActions(data.payload.actions);
      });
  }, [id]);

  function hasLinkError(str) {
    if (str == null || str.length <= 0) {
      return "target link is required";
    }

    if (!str.startsWith("https://")) {
      return "link must start with https://";
    }
    return false;
  }

  function areFieldsValid() {
    return (
      name != null &&
      image != null &&
      body != null &&
      name.length > 0 &&
      image.length > 0 &&
      hasLinkError(icon) === false &&
      hasLinkError(image) === false &&
      hasLinkError(link) === false &&
      body.length > 0
    );
  }

  function onCreate() {
    if (areFieldsValid()) {
      if (id == null) {
        createWebpushMessage(name, {
          title,
          icon,
          image,
          link,
          body,
          vibrate,
          dir,
          actions,
        })
          .then(() => {
            navigate("/webpush/messages", { replace: true });
          })
          .catch((error) => {
            setValidationError(error.message);
          });
      } else {
        updateWebpushMessage(id, name, {
          title,
          icon,
          image,
          body,
          link,
          vibrate,
          dir,
          actions,
        }).then(() => {
          navigate("/webpush/messages", { replace: true });
        });
      }
    } else {
      setValidationError(
        "Please fill in all required fields and fix any validation errors."
      );
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      {id == null ? 'Create New' : 'Update'} Webpush Message
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDButton variant="gradient" color="dark">
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;add new
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <Grid container justifyContent="space-between" pt={3} mx={2} mb={2}>
                <Grid item xl={6}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={name == null || name.length == 0}
                        helperText={name == null || name.length <= 0 ? "name is required" : ""}
                        type="text"
                        label="Name"
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        error={title == null || title.length == 0}
                        helperText={title == null || title.length == 0 ? "title is required" : ""}
                        type="text"
                        label="Title"
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        value={icon}
                        onChange={(e) => setIcon(e.target.value)}
                        error={hasLinkError(icon)}
                        helperText={hasLinkError(icon)}
                        type="text"
                        label="Icon URI"
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        value={image}
                        onChange={(e) => setImage(e.target.value)}
                        error={hasLinkError(image)}
                        helperText={hasLinkError(image)}
                        type="text"
                        label="Image URI"
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        error={hasLinkError(link)}
                        helperText={hasLinkError(link)}
                        type="text"
                        label="Target Link"
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        error={body == null || body.length === 0}
                        helperText={body == null || body.length === 0 ? "message is required" : ""}
                        type="text"
                        label="Message"
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={3}
                      lineHeight={1}
                    >
                      <MDTypography variant="h6">Vibrate</MDTypography>
                      <Switch
                        checked={vibrate}
                        onChange={() => setVibrate(!vibrate)}
                      />
                    </MDBox>
                    <FormControl sx={{ minWidth: 80 }}>
                      <InputLabel id="select-dir-label">Dir</InputLabel>
                      <Select
                        value={dir}
                        label="dir"
                        labelId="select-dir-label"
                        onChange={(e) => setDir(e.target.value)}
                        placeholder="Select an dir"
                      >
                        <MenuItem key="auto" value="auto">
                          Auto
                        </MenuItem>
                        <MenuItem key="ltr" value="ltr">
                          Left
                        </MenuItem>
                        <MenuItem key="rtl" value="rtl">
                          Right
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormValidationError
                      error={validationError}
                      onDismiss={() => {
                        setValidationError(null);
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xl={6}>
                  <ViewWebpushMessageDetailsComponent message={{
                    payload: {
                      title,
                      icon,
                      image,
                      link,
                      body,
                      vibrate,
                      dir,
                      actions,
                    }
                  }}></ViewWebpushMessageDetailsComponent>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={6}>
                    <MDButton
                      variant="gradient"
                      color="white"
                      fullWidth
                      onClick={() =>
                        navigate("/webpush-message", { replace: true })
                      }
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={onCreate}
                      disabled={!areFieldsValid()}
                    >
                      {id == null ? 'Create' : 'Update'}
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
