import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import {
  Box,
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useEffect, useState } from "react";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { ActionTableComp } from "components/ActionTableComp";
import {
  deleteWebpushSubscription,
  findWebpushSubscriptions,
} from "repositories/webpush-subscription-repository";
import { countries } from "layouts/dispatch/create/country-list";
import { findWebpush } from "repositories/webpush-repository";

export function WebPushSubscriptionList() {
  const [total, setTotal] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [webpushList, setWebpushList] = useState([]);
  const itemsPerPageOptions = [10, 25, 50, 100, 500, 1000];

  const [filters, setFilters] = useState({
    countryCode: undefined,
    webpushId: undefined,
    startDate: undefined,
    endDate: undefined,
  });

  function fetchData(pageIndex) {
    setIsLoading(true);
    findWebpushSubscriptions(
      {
        ...filters,
        startDate: filters.startDate ? new Date(filters.startDate) : undefined,
        endDate: filters.endDate ? new Date(filters.endDate) : undefined,
      },
      (pageIndex - 1) * itemsPerPage,
      itemsPerPage
    )
      .then((result) => {
        const [data, count] = result.data;
        setSubscriptions(data);
        setTotal(count);
      })
      .finally(() => setIsLoading(false));
    findWebpush().then((result) => setWebpushList(result.data[0]));
  }

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, itemsPerPage, filters]);

  function onDelete(subscription) {
    deleteWebpushSubscription(subscription._id).then(() => {
      setSubscriptions(subscriptions.filter((_) => _._id !== subscription._id));
    });
  }

  function handlePageChange(event, value) {
    setCurrentPage(value);
  }

  function handleFilterChange(event) {
    const { name, value } = event.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value === "all" ? undefined : value,
    }));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Webpush Subscriptions
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} px={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <FormControl variant="outlined" fullWidth style={{ minWidth: '200px' }}>
                      <InputLabel id="country-code-label">Country Code</InputLabel>
                      <Select
                        labelId="country-code-label"
                        name="countryCode"
                        value={filters.countryCode}
                        onChange={handleFilterChange}
                        label="Country Code"
                      >
                        <MenuItem key='all' value='all'>All Countries</MenuItem>
                        {Object.keys(countries).map((code) => (
                          <MenuItem key={code} value={countries[code]}>
                            {countries[code]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" fullWidth style={{ minWidth: '200px' }}>
                      <InputLabel id="select-webpush-label">Site</InputLabel>
                      <Select
                        labelId="select-webpush-label"
                        name="webpushId"
                        value={filters.webpushId}
                        label="Site"
                        onChange={handleFilterChange}
                      >
                        <MenuItem key='all' value='all'>All Webpush</MenuItem>
                        {webpushList.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Start Date"
                      type="date"
                      name="startDate"
                      InputLabelProps={{ shrink: true }}
                      value={filters.startDate}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="End Date"
                      type="date"
                      name="endDate"
                      InputLabelProps={{ shrink: true }}
                      value={filters.endDate}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <MDBox component="thead">
                      <TableRow>
                        <DataTableHeadCell width="auto" align="left">
                          Webpush
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Country
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Created At
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Params
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Actions
                        </DataTableHeadCell>
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {subscriptions.map((webpush) => (
                        <TableRow key={webpush._id}>
                          <TableCell>{webpush.webpush?.name}</TableCell>
                          <TableCell>{webpush.countryCode}</TableCell>
                          <TableCell>
                            {webpush.createdAt.split("T")[0]}
                          </TableCell>
                          <TableCell>
                            {JSON.stringify(webpush.customFields)}
                          </TableCell>
                          <TableCell>
                            <ActionTableComp
                              onDelete={() => onDelete(webpush)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <MDBox
                    pt={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <MDTypography variant="caption" mr={1}>
                      Items per page:
                    </MDTypography>
                    <select
                      value={itemsPerPage}
                      onChange={(event) =>
                        setItemsPerPage(Number(event.target.value))
                      }
                    >
                      {itemsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </MDBox>
                  <MDBox
                    pt={3}
                    mx={2}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="caption">
                      Page {currentPage} of {Math.ceil(total / itemsPerPage)}
                    </MDTypography>
                    <Pagination
                      count={Math.ceil(total / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="secondary"
                      size="large"
                    />
                  </MDBox>
                  {isLoading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      marginBottom="3rem"
                    >
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : null}
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
