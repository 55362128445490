import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "companies";

export async function createApiKey(name) {
  const companyId = localStorage.getItem("companyId");

  return client.post(`${path}/${companyId}/api-keys`, {
    name,
  });
}

export const findApiKeysByCompanyId = cache("findApiKeysByCompanyId", () => {
  const companyId = localStorage.getItem("companyId");

  return client.get(`${path}/${companyId}/api-keys`);
});
