const data = {};

export function cache(id, fun, keyFilter = null) {
  return async (...args) => {
    let keys = {};

    if (keyFilter) {
      keyFilter.forEach((element) => {
        keys[element] = args[element];
      });
    } else {
      keys = { ...args };
    }

    const key = `${id}_${JSON.stringify(keys)}`;

    if (data[key] != null) {
      return data[key];
    }

    setTimeout(() => {
      delete data[key];
    }, 60000);

    data[key] = fun(...args);
    return data[key];
  };
}
