import { client } from "./client";

const path = "webpush";

export async function createWebpush(companyId, name, subject) {
  return client.post(path, {
    companyId,
    name,
    subject,
  });
}

export async function updateWebpush(id, payload) {
  return client.patch(`${path}/${id}`, payload);
}

export function findWebpush(query, skip = 0, limit = 1000) {
  return client.get(path, {
    params: {
      filter: JSON.stringify({
        companyId: localStorage.getItem("companyId"),
        ...query,
      }),
      skip,
      limit,
    },
  });
}

export function deleteWebpush(id) {
  return client.delete(`${path}/${id}`);
}

export function findWebpushById(id) {
  return client.get(`${path}/${id}`);
}
