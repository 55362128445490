import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import { useEffect, useState } from "react";
import {
  dashboardContacts,
  dashboardTotalContacts,
} from "repositories/dashboard-repository";
import { ContactStatisticsCard } from "examples/Cards/StatisticsCards/ContactStatisticsCard";
import { SubscriptionStatisticsCard } from "examples/Cards/StatisticsCards/SubscriptionsStatisticsCard";

export function Dashboard() {
  const [contactStatistics, setContactStatistics] = useState({
    totalContacts: 0,
    activeContacts: 0,
    totalSubscriptions: 0,
    totalInactiveSubscriptions: 0,
  });
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    dashboardContacts(7).then((result) => {
      setContacts(
        result.data.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )
      );
    });
    dashboardTotalContacts().then((result) => {
      setContactStatistics(result.data);
    });
  });

  const tasks = {
    labels: contacts.map((_) => {
      const [, month, day] = _.date.split("T")[0].split("-");

      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}`;
    }),
    datasets: {
      label: "Contacts",
      data: contacts.map((_) => _.count),
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ContactStatisticsCard
                color="dark"
                icon="persons"
                count={contactStatistics.totalContacts}
                activeCount={contactStatistics.activeContacts}
                percentage={{
                  color: "success",
                  amount: "+0%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <SubscriptionStatisticsCard
                color="dark"
                icon="notifications"
                count={contactStatistics.totalSubscriptions}
                inactiveCount={contactStatistics.totalInactiveSubscriptions}
                percentage={{
                  color: "success",
                  amount: "+0%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Weekly contacts"
                  description="Contacts per day"
                  date="Last 7 days"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/*         <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
