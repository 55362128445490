import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "lists";

export async function createList(companyId, name, description) {
  return client.post(path, {
    companyId,
    name,
    description,
  });
}

export const findLists = cache("findLists", (name) =>
  client.get(path, {
    params: {
      companyId: localStorage.getItem("companyId"),
      name,
    },
  })
);

export async function updateList(id, name, description) {
  return client.patch(`${path}/${id}`, {
    name,
    description,
  });
}

export const findListId = cache("findListId", (listId) =>
  client.get(`${path}/${listId}`)
);

export function deleteList(listId) {
  return client.delete(`${path}/${listId}`);
}
