import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { MDButton } from "components/MDButton";
import { useEffect, useState } from "react";
import {
  createApiKey,
  findApiKeysByCompanyId,
} from "repositories/api-key-repository";
import { Box, Button, Grid, Icon, Modal } from "@mui/material";
import MDInput from "components/MDInput";

function AddNewModal({ open, setOpen, onCreate }) {
  const [name, setName] = useState("");
  const handleClose = () => {
    setOpen(false);
  };

  function handleCreate() {
    createApiKey(name).then(() => {
      onCreate();
      setOpen(false);
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          minWidth: "800px",
          borderRadius: "0.8rem",
          boxShadow: 24,
        }}
      >
        <div
          id="modal-modal-title"
          style={{
            padding: "0.8rem",
          }}
        >
          Add New API Key
          <Button onClick={handleClose}>
            <Icon>close</Icon>
          </Button>
        </div>
        <div id="modal-modal-description">
          <MDBox p={2}>
            <MDInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={name.length === 0}
              helperText={!name.length ? "name is required" : ""}
              type="email"
              label="Name"
              fullWidth
            />
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={6} sm={6}>
                <MDButton
                  variant="gradient"
                  color="white"
                  fullWidth
                  onClick={handleClose}
                >
                  Cancel
                </MDButton>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleCreate}
                >
                  Create
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </div>
      </Box>
    </Modal>
  );
}

export function ApiKeysList({ shadow }) {
  const [apiKeys, setApiKeys] = useState([]);
  const [open, setOpen] = useState(false);

  function refreshList() {
    findApiKeysByCompanyId().then((result) => {
      setApiKeys(result.data);
    });
  }

  useEffect(() => {
    refreshList();
  }, []);

  const renderProfiles = apiKeys.map((apiKey) => (
    <MDBox
      key={apiKey._id}
      component="li"
      display="flex"
      alignItems="center"
      py={1}
      mb={1}
    >
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <MDTypography variant="button" fontWeight="medium">
          {apiKey.name}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {apiKey.token}
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
        <MDButton variant="text" color="info">
          Copy
        </MDButton>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              API Keys
            </MDTypography>
          </Grid>
          <Grid item>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => setOpen(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;create new key
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
      <AddNewModal open={open} setOpen={setOpen} onCreate={refreshList} />
    </Card>
  );
}
ApiKeysList.defaultProps = {
  shadow: true,
};
ApiKeysList.propTypes = {
  shadow: PropTypes.bool,
};
