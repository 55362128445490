import { Button, Grid, Icon, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { IntegrationType, ServiceType } from "../enums";

function Item({ title, description, icon, backgroundColor, onClick }) {
  return (
    <button
      style={{
        position: "relative",
        marginBottom: "0.7rem",
        display: "block",
        padding: "12px 12px 12px 54px",
        textDecoration: "none",
        borderRadius: "5px",
        backgroundColor,
        boxSizing: "border-box",
        width: "100%",
        border: "1px rgba(0, 0, 0, 0.12) solid",
        boxShadow: "0px 2px 4px 0px rgb(0 0 0 / 10%)",
        textAlign: "left",
      }}
      onClick={onClick}
    >
      <Icon style={{ fontSize: "48px !important" }}>{icon}</Icon>
      <div className="addblock-label">{title}</div>
      <div className="addblock-descript">{description}</div>
    </button>
  );
}

const categories = [
  {
    name: "Email Transporter",
    components: [
      {
        type: IntegrationType.EMAIL,
        serviceType: ServiceType.AWS_EMAIL,
        title: "AWS SNS Email Transporter",
        description: "Send emails",
        icon: "email",
        backgroundColor: "white",
        data: {
          sender: "",
          accessKeyId: null,
          secretAccessKey: null,
          region: null,
        },
      },
      {
        type: IntegrationType.EMAIL,
        serviceType: ServiceType.SENDGRID,
        title: "Sendgrid",
        description: "Send emails",
        icon: "email",
        backgroundColor: "white",
        data: {
          sender: "",
          token: null,
        },
      },
      {
        type: IntegrationType.EMAIL,
        serviceType: ServiceType.SENDBLUE,
        title: "Sendblue",
        description: "Send emails",
        icon: "email",
        backgroundColor: "white",
        data: {
          sender: "",
          token: null,
        },
      },
    ],
  },
  {
    name: "Email Verification",
    components: [
      {
        type: IntegrationType.EMAIL_VERIFICATION,
        serviceType: ServiceType.QUICK_EMAIL,
        title: "Quick email verification",
        description: "Verify valid emails",
        icon: "email",
        backgroundColor: "white",
        data: {
          token: null,
        },
      },
    ],
  },
  {
    name: "SMS",
    components: [
      {
        type: IntegrationType.SMS,
        serviceType: ServiceType.CONTELE,
        title: "Contele",
        description: "Send and schedule sms messages",
        icon: "message",
        backgroundColor: "white",
        data: {
          token: null,
        },
      },
    ],
  },
];

export function IntegrationComponentSelector({ open, setOpen, onSelect }) {
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = (component) => {
    handleClose();

    if (onSelect != null) {
      onSelect(component);
    }
  };
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          minWidth: "800px",
          borderRadius: "0.8rem",
          boxShadow: 24,
        }}
      >
        <div
          id="modal-modal-title"
          style={{
            padding: "0.8rem",
          }}
        >
          Select integration
          <Button onClick={handleClose}>
            <Icon>close</Icon>
          </Button>
        </div>
        <div id="modal-modal-description">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div style={{ backgroundColor: "#4e5468", marginRight: "8px" }}>
                {categories.map((category, i) => (
                  <button
                    key={category.name}
                    onClick={() => {
                      setSelectedCategoryIndex(i);
                    }}
                    style={{
                      width: "100%",
                      padding: "1rem",
                      direction: "ltr",
                      fontSize: "14px",
                      lineHeight: "14px",
                      color: "#fff",
                      margin: 0,
                      cursor: "pointer",
                      textAlign: "left",
                      position: "relative",
                      border: "1px solid #353a4a",
                      backgroundColor:
                        selectedCategoryIndex === i ? "#353a4a" : "transparent",
                      fontWeight: 600,
                    }}
                  >
                    {category.name}
                  </button>
                ))}
              </div>
            </Grid>
            <Grid item xs={8}>
              {categories[selectedCategoryIndex].components.map((component) => (
                <Item
                  key={component.title}
                  title={component.title}
                  description={component.description}
                  backgroundColor={component.backgroundColor}
                  icon={component.icon}
                  onClick={() => handleSelect(component)}
                />
              ))}
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}
