import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MDButton } from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { useState } from "react";
import { FormValidationError } from "layouts/authentication/components/form-validation-error";
import { useNavigate } from "react-router-dom";
import { createDispatch } from "repositories/dispatch-repository";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { findEmails } from "repositories/email-repository";
import { findIntegrations } from "repositories/integration-repository";
import { findLists } from "repositories/list-repository";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import MDInput from "components/MDInput";
import { findSms } from "repositories/sms-repository";
import { findWebpushMessages } from "repositories/webpush-message-repository";
import { findWebpush } from "repositories/webpush-repository";
import { Loading } from "components/loading/loading";
import { countries } from "./country-list";

function ButtonOption({ icon, name, isActive, onClick }) {
  return (
    <div
      style={{
        marginRight: "1rem",
        backgroundColor: isActive ? "#d6ac2c" : "transparent",
        borderRadius: "9px",
      }}
    >
      <MDBox
        display="grid"
        justifyContent="center"
        alignItems="center"
        color="white"
        width="4rem"
        bgColor="info"
        height="4rem"
        shadow="md"
        borderRadius="lg"
        variant="gradient"
        onClick={() => onClick()}
      >
        <Icon fontSize="default">{icon}</Icon>
      </MDBox>
      <p style={{ margin: "auto", fontSize: "13px", textAlign: "center" }}>
        {name}
      </p>
    </div>
  );
}

function SelectValue({ value, label, options, onChangeValue }) {
  return (
    <FormControl sx={{ minWidth: 280 }}>
      <InputLabel id="select-email-label">{label}</InputLabel>
      <Select
        labelId="select-email-label"
        value={value}
        style={{ marginRight: "1rem" }}
        label={label}
        onChange={(e) => onChangeValue(e.target.value)}
        placeholder={`Select an email ${label.toLowerCase()}`}
      >
        {options.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function DispatchesCreate() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(null);
  const [createdAtInterval, setCreatedAtInterval] = useState([null, null]);
  const [integrationId, setIntegrationId] = useState(null);
  const [description, setDescription] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [type, setType] = useState("EMAIL");
  const [emailId, setEmailId] = useState(null);
  const [smsId, setSmsId] = useState(null);
  const [webpushId, setWebpushId] = useState(null);
  const [webpushMessageId, setWebpushMessageId] = useState(null);
  const [lists, setLists] = useState([]);
  const [emails, setEmails] = useState([]);
  const [smss, setSmss] = useState([]);
  const [webpushMessages, setWebpushMessages] = useState([]);
  const [webpushList, setWebpushList] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [scheduleExecution, setScheduleExecution] = useState(false);
  const [runAt, setRunAt] = useState(new Date());
  const companyId = localStorage.getItem("companyId");
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    listIds: [],
  });

  useState(() => {
    findLists().then((result) => setLists(result.data));
    findEmails().then((result) => setEmails(result.data));
    findSms().then((result) => setSmss(result.data));
    findWebpush().then((result) => setWebpushList(result.data[0]));
    findWebpushMessages({}, 0, 10000).then((result) => {
      setWebpushMessages(result.data[0]);
    });
    findIntegrations().then((result) => setIntegrations(result.data));
  }, []);

  const handleFieldChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  function onCreate() {
    setIsLoading(true);
    createDispatch(
      companyId,
      description,
      type,
      {
        countryCode,
        startDate: createdAtInterval[0],
        endDate: createdAtInterval[1],
        listIds: formState.listIds,
        emailId,
        smsId,
        webpushId,
        webpushMessageId,
        integrationId,
      },
      scheduleExecution ? runAt : undefined
    )
      .then(() => {
        navigate("/dispatches", { replace: true });
      })
      .catch((error) => {
        setValidationError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  let selectComponent;

  if (type === "EMAIL") {
    selectComponent = (
      <SelectValue
        value={emailId}
        label="Email"
        options={emails}
        onChangeValue={setEmailId}
      />
    );
  } else if (type === "SMS") {
    selectComponent = (
      <SelectValue
        value={smsId}
        label="SMS"
        options={smss}
        onChangeValue={setSmsId}
      />
    );
  } else {
    selectComponent = (
      <>
        <SelectValue
          value={webpushId}
          label="WEB Push Filter"
          options={webpushList}
          onChangeValue={setWebpushId}
        />
        <SelectValue
          value={webpushMessageId}
          label="WEB Push Message"
          options={webpushMessages}
          onChangeValue={setWebpushMessageId}
        />
        <Button variant="contained" color="primary" onClick={() => window.open(`/webpush/messages/${webpushMessageId}`, '_blank')} style={{ marginLeft: '10px', color: 'white' }}>
          View
        </Button>
      </>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Create New Dispatch
                    </MDTypography>
                  </Grid>
                  <Grid item />
                </Grid>
              </MDBox>
              <MDBox pt={3} mx={2} mb={2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      error={description.length === 0}
                      helperText={
                        !description.length ? (
                          <FormHelperText style={{ color: "red" }}>
                            description is required
                          </FormHelperText>
                        ) : null
                      }
                      type="text"
                      label="Description"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Autocomplete
                        value={countryCode}
                        options={Object.keys(countries)}
                        getOptionLabel={(option) => countries[option]}
                        renderInput={(params) => (
                          <TextField {...params} label="Select a country" />
                        )}
                        renderOption={(props, option) => (
                          <li key={option} {...props}>
                            <span>{countries[option] || ""}</span>
                          </li>
                        )}
                        onSelect={(e) => {
                          const i = Object.values(countries).findIndex(
                            (_) => _ === e.target.value
                          );

                          if (i > -1) {
                            setCountryCode(Object.keys(countries)[i]);
                          }
                        }}
                        style={{ width: 300 }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{
                          width: "200px",
                          height: "80px",
                          marginRight: "1rem",
                        }}
                        select
                        name="listIds"
                        id="listIds"
                        variant="outlined"
                        placeholder="Lists"
                        label="Lists"
                        SelectProps={{
                          multiple: true,
                          value: formState.listIds,
                          onChange: handleFieldChange,
                        }}
                      >
                        {lists.map((list) => (
                          <MenuItem key={list._id} value={list._id}>
                            {list.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <div
                          style={{ width: "500px", margin: "0px !important" }}
                        >
                          <DateRangePicker
                            calendars={1}
                            value={createdAtInterval}
                            onChange={setCreatedAtInterval}
                            renderInput={(startProps, endProps) => (
                              <>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </>
                            )}
                          />
                        </div>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mb={2}>
                    {scheduleExecution ? (
                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateTimePicker
                            label="Run At"
                            value={runAt}
                            onChange={setRunAt}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    ) : null}
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={scheduleExecution}
                            onChange={() =>
                              setScheduleExecution(!scheduleExecution)
                            }
                          />
                        }
                        label="Schedule execution"
                      />
                    </Grid>
                  </Grid>
                  <p>Select a dispatch mode</p>
                  <MDBox p={2} mx={3} display="flex" justifyContent="center">
                    <div style={{ display: "flex" }}>
                      <ButtonOption
                        name="Email"
                        icon="email"
                        isActive={type === "EMAIL"}
                        onClick={() => setType("EMAIL")}
                      />
                      <ButtonOption
                        name="SMS"
                        icon="sms"
                        isActive={type === "SMS"}
                        onClick={() => setType("SMS")}
                      />
                      <ButtonOption
                        name="Webpush"
                        icon="notifications"
                        isActive={type === "WEB_PUSH"}
                        onClick={() => setType("WEB_PUSH")}
                      />
                    </div>
                  </MDBox>
                  {selectComponent}
                  {type === "WEB_PUSH" ? null : (
                    <FormControl sx={{ minWidth: 280 }}>
                      <InputLabel id="select-integration-label">
                        Integration
                      </InputLabel>
                      <Select
                        labelId="select-integration-label"
                        value={integrationId}
                        style={{ marginRight: "1rem" }}
                        label="Integration"
                        onChange={(e) => setIntegrationId(e.target.value)}
                        placeholder="Select an integration"
                      >
                        {integrations.map((integration) => (
                          <MenuItem
                            key={integration._id}
                            value={integration._id}
                          >
                            {integration.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormValidationError
                    error={validationError}
                    onDismiss={() => {
                      setValidationError(null);
                    }}
                  />
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="white"
                        fullWidth
                        onClick={() =>
                          navigate("/dispatches", { replace: true })
                        }
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        disabled={description.length === 0}
                        onClick={onCreate}
                      >
                        Execute
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Loading isLoading={isLoading} />
      <Footer />
    </DashboardLayout>
  );
}
