import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { routes } from "routes";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { EmailCreate } from "layouts/emails/create/email-create";
import { Register } from "layouts/authentication/register";
import { isUserLogged } from "repositories/user-utils";
import { ContactCreate } from "layouts/contacts/create/contact-create";
import { AutomationCreate } from "layouts/automations/create/automation-create";
import { IntegrationCreate } from "layouts/integrations/create/integration-create";
import { SmsCreate } from "layouts/sms/create/sms-create";
import { ListCreate } from "layouts/lists/create/list-create";
import { DispatchesCreate } from "layouts/dispatch/create/dispatch-create";
import { CompanyProfile } from "layouts/company-profile/CompanyProfile";
import { WebpushCreate } from "layouts/web-push/create/webpush-create";
import { WebpushMessageCreate } from "layouts/web-push-messages/create/webpush-message-create";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes
      .filter((route) => route.auth === false || isUserLogged())
      .filter((_) => _.route)
      .map((route) => (
        <>
          <Route path={route.route} element={route.component} key={route.key} />
          {getRoutes(route.subRoutes || [])}
        </>
      ));

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Autcamp"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route
            path="/emails/create"
            element={<EmailCreate />}
            key="email-create"
          />
          <Route path="/profile" element={<CompanyProfile />} key="profile" />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Autcamp"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route
          path="/contacts/create"
          element={<ContactCreate />}
          key="contact-create"
        />
        <Route
          path="/contacts/:id"
          element={<ContactCreate />}
          key="contact-edit"
        />
        <Route
          path="/emails/create"
          element={<EmailCreate />}
          key="email-create"
        />
        <Route path="/emails/:id" element={<EmailCreate />} key="email-edit" />
        <Route
          path="/integrations/create"
          element={<IntegrationCreate />}
          key="integration-create"
        />
        <Route
          path="/integrations/:id"
          element={<IntegrationCreate />}
          key="integration-edit"
        />
        <Route
          path="/automations/create"
          element={<AutomationCreate />}
          key="automation-create"
        />
        <Route
          path="/automations/:id"
          element={<AutomationCreate />}
          key="automation-edit"
        />
        <Route
          path="/lists/create"
          element={<ListCreate />}
          key="list-create"
        />
        <Route path="/lists/:id" element={<ListCreate />} key="list-edit" />
        <Route
          path="/dispatches/create"
          element={<DispatchesCreate />}
          key="dispatch-create"
        />
        <Route path="/sms/create" element={<SmsCreate />} key="sms-create" />
        <Route path="/sms/:id" element={<SmsCreate />} key="sms-edit" />
        <Route
          path="/webpush/create"
          element={<WebpushCreate />}
          key="webpush-create"
        />
        <Route
          path="/webpush/:id"
          element={<WebpushCreate />}
          key="webpush-edit"
        />
        <Route
          path="/webpush/messages/create"
          element={<WebpushMessageCreate />}
          key="webpush-messages-create"
        />
        <Route
          path="/webpush/messages/:id"
          element={<WebpushMessageCreate />}
          key="webpush-messages-edit"
        />
        {isUserLogged() ? (
          <Route path="/profile" element={<CompanyProfile />} key="profile" />
        ) : (
          <Route
            path="/authentication/register"
            element={<Register />}
            key="/authentication/register"
          />
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={isUserLogged() ? "/dashboard" : "/authentication/login"}
            />
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
