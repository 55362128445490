import axios from "axios";
import { getLoggedUser, isUserLogged } from "./user-utils";

export const client = axios.create({
  baseURL:
    window.location.hostname === "localhost"
      ? "http://localhost:4000"
      : "https://webapi.autcamp.com",
  headers: isUserLogged()
    ? {
      Authorization: `Bearer ${getLoggedUser().token}`,
    }
    : undefined,
});
