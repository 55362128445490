import { Button, Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: '30px'
  },
  media: {
    height: 140,
  },
  icon: {
    width: 50,
    height: 50,
    marginRight: '10px',
  },
  linkButton: {
    marginTop: '10px',
    color: 'white'
  },
}));

export function ViewWebpushMessageDetailsComponent({ message }) {
  const classes = useStyles();

  message = {
    "payload": {
      "title": message?.payload?.title ?? "TITLE EXEMPLE",
      "icon": message?.payload?.icon ?? "https://logopng.com.br/logos/uber-156.png",
      "image": message?.payload?.image ?? "https://logopng.com.br/logos/uber-156.png",
      "link": message?.payload?.link ?? "https://exemple.com/clicklink/?utm_source=yoursource",
      "body": message?.payload?.body ?? "Body content...",
      "vibrate": message?.payload?.vibrate ?? true,
      "dir": message?.payload?.dir ?? "auto",
      "actions": message?.payload?.actions ?? []
    },
    statistics: message?.statistics
  };

  return (
    <Container>
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <img src={message.payload.icon} alt="icon" className={classes.icon} />
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h2">
                {message.payload.title}
              </Typography>
              <Typography color="textSecondary">
                {/* {message.name} */}
              </Typography>
            </Grid>
          </Grid>
          <CardMedia
            className={classes.media}
            image={message.payload.image}
            title="Notification Image"
          />
          <Typography variant="body1" component="p" gutterBottom>
            {message.payload.body}
          </Typography>
          <span>
            {message.payload.link}
          </span>
          <br></br>
          <Button
            variant="contained"
            color="primary"
            href={message.payload.link}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkButton}
          >
            Go to Link
          </Button>
          {message.statistics ? (
            <>
              <Typography variant="body2" color="textSecondary" component="p">
                Send Count: {message.statistics.sendCount}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Open Count: {message.statistics.openCount} ({(message.statistics.openCount / message.statistics.sendCount * 100).toFixed(1)}%)
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Click Count: {message.statistics.clickCount} ({(message.statistics.clickCount / message.statistics.sendCount * 100).toFixed(1)}%)
              </Typography>
            </>
          )
            : null}
        </CardContent>
      </Card>
    </Container>
  );
}
