import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { Link, useNavigate } from "react-router-dom";
import { MDButton } from "components/MDButton";
import team2 from "assets/images/team-2.jpg";
import {
  Box,
  CircularProgress,
  Icon,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { deleteContact, findContacts } from "repositories/contact-repository";
import MDAvatar from "components/MDAvatar";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import MDBadge from "components/MDBadge";
import { ActionTableComp } from "components/ActionTableComp";

const ContactStatus = {
  0: "INACTIVE",
  1: "ACTIVE",
};

function Author({ image, name, email }) {
  return (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
}

export function ContactList() {
  const [total, setTotal] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPageOptions = [10, 25, 50, 100, 500, 1000];
  const navigate = useNavigate();

  function fetchData(pageIndex) {
    setIsLoading(true);
    findContacts({}, (pageIndex - 1) * itemsPerPage, itemsPerPage)
      .then((result) => {
        const [data, count] = result.data;
        setContacts(data);
        setTotal(count);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, itemsPerPage]);

  function onEdit(contact) {
    navigate(`/contacts/${contact._id}`, { replace: true });
  }

  function onDelete(contact) {
    deleteContact(contact._id).then(() => {
      setContacts(contacts.filter((_) => _._id !== contact._id));
    });
  }

  function handlePageChange(event, value) {
    setCurrentPage(value);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Contacts
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Link to="/contacts/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;add new
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <MDBox component="thead">
                      <TableRow>
                        <DataTableHeadCell width="auto" align="left">
                          Name
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Phone
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Status
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Actions
                        </DataTableHeadCell>
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {contacts.map((contact) => (
                        <TableRow key={contact._id}>
                          <TableCell>
                            <Author
                              image={team2}
                              name={contact.fullname}
                              email={contact.email}
                            />
                          </TableCell>
                          <TableCell>{contact.phone}</TableCell>
                          <TableCell>
                            <MDBadge
                              badgeContent={ContactStatus[contact.status]}
                              style={{ margin: "auto" }}
                              color="success"
                              variant="gradient"
                              size="sm"
                            />
                          </TableCell>
                          <TableCell>
                            <ActionTableComp
                              onDelete={() => onDelete(contact)}
                              onEdit={() => onEdit(contact)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <MDBox
                    pt={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <MDTypography variant="caption" mr={1}>
                      Items per page:
                    </MDTypography>
                    <select
                      value={itemsPerPage}
                      onChange={(event) =>
                        setItemsPerPage(Number(event.target.value))
                      }
                    >
                      {itemsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </MDBox>
                  <MDBox pt={3} display="flex" justifyContent="space-between">
                    <MDTypography variant="caption">
                      Page {currentPage} of {Math.ceil(total / itemsPerPage)}
                    </MDTypography>
                    <Pagination
                      count={Math.ceil(total / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="secondary"
                      size="large"
                    />
                  </MDBox>
                  {isLoading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      marginBottom="3rem"
                    >
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : null}
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
