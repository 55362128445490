import { Button, Icon } from "@mui/material";
import styles from "./delete-button.module.css";

export function DeleteButton({ onEdit = null, onDelete = null }) {
  return (
    <div>
      {onEdit ? (
        <Button
          className={styles.buttonEdit}
          color="error"
          startIcon={<Icon>edit</Icon>}
          onClick={onEdit}
        >
          edit
        </Button>
      ) : null}
      {onDelete ? (
        <Button
          className={styles.buttonDelete}
          color="error"
          startIcon={<Icon>delete</Icon>}
          onClick={onDelete}
        >
          delete
        </Button>
      ) : null}
    </div>
  );
}
