import ObjectID from "bson-objectid";
import { StepType, TriggerType } from "./components/enums";

const t1 = new ObjectID().toHexString();
const e2 = new ObjectID().toHexString();

export const defaultNodes = [
  {
    _id: t1,
    type: StepType.TRIGGER,
    component: TriggerType.CONTACT_CREATE,
    data: {},
    nextNodeIds: [e2],
  },
  {
    _id: e2,
    type: StepType.END,
    data: {},
    nextNodeIds: [],
  },
];
