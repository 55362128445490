export function AddNewTrigger({ onClick }) {
  return (
    <button
      style={{
        border: "2px dashed black",
        padding: "0.7rem",
        borderRadius: "0.7rem",
      }}
      onClick={onClick}
    >
      Add New Trigger
    </button>
  );
}
