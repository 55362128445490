import { Icon } from "@mui/material";
import { DeleteButton } from "./delete-button.component";

export function WaitComponent({ style, data, onEdit, onDelete }) {
  let rest = data.time;
  const days = Math.floor(rest / (60 * 24));
  rest %= 60 * 24;
  const hours = Math.floor(rest / 60);
  const minutes = rest % 60;

  const message = [
    days > 0 ? `${days} days` : null,
    hours > 0 ? `${hours} hours` : null,
    minutes > 0 ? `${minutes} minutes` : null,
  ]
    .filter((_) => _ != null)
    .join(" and ");

  return (
    <button type="button" style={style}>
      <div>
        <Icon
          style={{
            paddingTop: "4px",
            marginRight: "4px",
          }}
        >
          access_time
        </Icon>
        <span>Wait for {message}</span>
      </div>
      <DeleteButton onEdit={onEdit} onDelete={onDelete} />
    </button>
  );
}
