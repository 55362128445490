import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { MDButton } from "components/MDButton";
import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { deleteSms, findSms } from "repositories/sms-repository";
import { ActionTableComp } from "components/ActionTableComp";

export function SmsList() {
  const [smsList, setSmsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    findSms()
      .then((result) => {
        setSmsList(result.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  function onEdit(sms) {
    window.open(`/sms/${sms._id}`, "_blank");
  }

  function onDelete(sms) {
    deleteSms(sms._id).then(() => {
      setSmsList(smsList.filter((_) => _._id != sms._id));
    });
  }

  const columns = [
    { Header: "name", accessor: "name", width: "45%", align: "left" },
    { Header: "description", accessor: "description", align: "left" },
    { Header: "modified at", accessor: "updatedAt", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = smsList.map((sms) => ({
    name: <div>{sms.name}</div>,
    description: <div>{sms.description}</div>,
    updatedAt: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {sms.updatedAt.split("T")[0]}
      </MDTypography>
    ),
    action: (
      <ActionTableComp
        onDelete={() => onDelete(sms)}
        onEdit={() => onEdit(sms)}
      />
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      SMS
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Link to="/sms/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;add new
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isLoading={isLoading}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
