import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

export function FormValidationError({ error, onDismiss }) {
  return error ? (
    <MDAlert color="error" dismissible onDismiss={onDismiss}>
      <MDTypography variant="body2" color="white">
        {error}
      </MDTypography>
    </MDAlert>
  ) : null;
}
