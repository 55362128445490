import "./loading.css";

export function Loading({ isLoading }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="loading-overlay">
      <div className="loading-spinner" />
    </div>
  );
}
