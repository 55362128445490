import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";
// Autcamp context
import { useMaterialUIController } from "context";
import { useState } from "react";
import { Collapse, List } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

function SidenavCollapse({ route, ...rest }) {
  const [controller] = useMaterialUIController();
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const collapseName = location.pathname.split("/").pop();
  const active =
    collapseName.includes(route.key.split("/").pop()) && !route.subRoutes;
  const subRoutes = route.subRoutes || [];

  function handleClick() {
    setOpen(!open);
  }

  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;

  return (
    <>
      <ListItem component="li" onClick={handleClick}>
        <NavLink key={route.key} to={route.route}>
          <MDBox
            {...rest}
            sx={(theme) =>
              collapseItem(theme, {
                active,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <ListItemIcon
              sx={(theme) =>
                collapseIconBox(theme, {
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                  active,
                })
              }
            >
              {typeof route.icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme, { active })}>
                  {route.icon}
                </Icon>
              ) : (
                route.icon
              )}
            </ListItemIcon>

            <ListItemText
              primary={route.name}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  active,
                })
              }
            />
          </MDBox>
        </NavLink>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 2 }}>
          {subRoutes.map((_) => (
            <SidenavCollapse key={_.key} route={_} />
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default SidenavCollapse;
