import { Button, Grid, Icon, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { deepCopy } from "utils/deep-copy";
import {
  ActionType,
  ConditionType,
  EventType,
  StepType,
  TriggerType,
} from "./components/enums";
import { IfElseEditor } from "./modals/if-else-component";
import { OpenEmailEditor } from "./modals/open-email";
import { SendEmailEditor } from "./modals/send-email";
import { SendSmsEditor } from "./modals/send-sms";
import { UnsubscribeListEditor } from "./modals/unsubscribe-list";
import { WaitEditor } from "./modals/wait-editor";
import { WaitUntilEditor } from "./modals/wait-until-component";
import { SendWebpushEditor } from "./modals/send-webpush";
import { SendWebpushTriggerEditor } from "./modals/send-webpush-trigger";

function Item({ title, description, icon, backgroundColor, onClick }) {
  return (
    <button
      style={{
        position: "relative",
        marginBottom: "0.7rem",
        display: "block",
        padding: "12px 12px 12px 54px",
        textDecoration: "none",
        borderRadius: "5px",
        backgroundColor,
        boxSizing: "border-box",
        width: "100%",
        border: "1px rgba(0, 0, 0, 0.12) solid",
        boxShadow: "0px 2px 4px 0px rgb(0 0 0 / 10%)",
        textAlign: "left",
      }}
      onClick={onClick}
    >
      <Icon style={{ fontSize: "48px !important" }}>{icon}</Icon>
      <div className="addblock-label">{title}</div>
      <div className="addblock-descript">{description}</div>
    </button>
  );
}

export function ComponentSelector({ node, open, setOpen, onSelect, editMode }) {
  const categories = [
    {
      name: "Triggers",
      components: [
        {
          nodeType: StepType.TRIGGER,
          componentType: TriggerType.CONTACT_CREATE,
          title: "Contact created",
          description: "When a new contact is created",
          icon: "people",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: Object.values(ActionType),
          },
        },
        {
          nodeType: StepType.TRIGGER,
          componentType: TriggerType.CONTACT_SUBSCRIBE_LIST,
          title: "Contact subscribe list",
          description: "When a contact subscribe to list",
          icon: "list",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: Object.values(ActionType),
          },
        },
        {
          nodeType: StepType.TRIGGER,
          componentType: TriggerType.WEBPUSH_SUBSCRIBE,
          title: "Webpush subscribe",
          description: "When an user subscribe webpush",
          icon: "notification",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: Object.values(ActionType),
          },
        },
      ],
    },
    {
      name: "Sending Options",
      components: [
        {
          nodeType: StepType.ACTION,
          componentType: ActionType.SEND_EMAIL,
          title: "Send an email",
          description: "Send an email to contact",
          icon: "email",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: [
              ActionType.SEND_EMAIL,
              ActionType.SEND_SMS,
              ActionType.REMOVE_FROM_LIST,
              ActionType.UNSUBSCRIBE,
            ],
          },
        },
        {
          nodeType: StepType.ACTION,
          componentType: ActionType.SEND_SMS,
          title: "Send an SMS",
          description: "Send an SMS to contact",
          icon: "message",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: [
              ActionType.SEND_EMAIL,
              ActionType.SEND_SMS,
              ActionType.REMOVE_FROM_LIST,
              ActionType.UNSUBSCRIBE,
            ],
          },
        },
        {
          nodeType: StepType.ACTION,
          componentType: ActionType.SEND_WEBPUSH,
          title: "Send a webpush",
          description: "Send a webpush message",
          icon: "message",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: [ActionType.SEND_WEBPUSH],
          },
        },
      ],
    },
    {
      name: "Conditions",
      components: [
        {
          nodeType: StepType.CONDITION,
          componentType: ConditionType.IF_ELSE,
          title: "If/Else condition",
          description: "Add condition and an alternative path to flow",
          icon: "email",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: Object.values(ActionType),
          },
        },
        {
          nodeType: StepType.CONDITION,
          componentType: ConditionType.WAIT,
          title: "Wait",
          description: "Wait a time to continue the flow",
          icon: "email",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: Object.values(ActionType),
          },
        },
        {
          nodeType: StepType.CONDITION,
          componentType: ConditionType.WAIT_UNTIL,
          title: "Wait until",
          description: "Wait a condition to continue the flow",
          icon: "clock",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: Object.values(ActionType),
          },
        },
      ],
    },
    {
      name: "Events",
      components: [
        {
          nodeType: StepType.EVENT,
          componentType: EventType.OPEN_EMAIL,
          title: "Open email",
          description: "Continue flow when contact open email",
          icon: "email",
          backgroundColor: "white",
          allowed: {
            [StepType.EVENT]: Object.values(EventType),
            [StepType.CONDITION]: Object.values(ConditionType),
            [StepType.ACTION]: Object.values(ActionType),
          },
        },
      ],
    },
  ].filter(
    (_) =>
      (node == null && _.name === "Triggers") ||
      (node != null && _.name != "Triggers")
  );

  const handleClose = () => setOpen(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [modalContent, setModalContent] = useState(null);
  let selectedNodeType = node != null ? node.type : null;
  let selectedComponentType = node != null ? node.component : null;

  function onSave(data) {
    setOpen(false);
    setModalContent(null);
    onSelect(node, selectedNodeType, selectedComponentType, data);
  }
  function onCancel() {
    setModalContent(null);

    if (editMode) {
      handleClose();
    }
  }

  function onSelectItem(nodeType, componentType) {
    selectedNodeType = nodeType;
    selectedComponentType = componentType;

    if (node != null) {
      node.data = deepCopy(node.data);
    }

    if (nodeType === StepType.TRIGGER) {
      switch (componentType) {
        case TriggerType.CONTACT_CREATE:
          onSave({});
          break;
        case TriggerType.CONTACT_SUBSCRIBE_LIST:
          onSave({});
          break;
        case TriggerType.WEBPUSH_SUBSCRIBE:
          setModalContent(
            <SendWebpushTriggerEditor
              data={{}}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
      }
    } else if (nodeType === StepType.ACTION) {
      switch (componentType) {
        case ActionType.REMOVE_FROM_LIST:
          setModalContent(
            <UnsubscribeListEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
        case ActionType.SEND_EMAIL:
          setModalContent(
            <SendEmailEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
        case ActionType.SEND_SMS:
          setModalContent(
            <SendSmsEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
        case ActionType.SEND_WEBPUSH:
          setModalContent(
            <SendWebpushEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
        case ActionType.UNSUBSCRIBE:
          setModalContent(
            <SendEmailEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
      }
    } else if (nodeType === StepType.CONDITION) {
      switch (componentType) {
        case ConditionType.HOUR_INTERVAL:
          setModalContent(
            <SendEmailEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
        case ConditionType.IF_ELSE:
          setModalContent(
            <IfElseEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
        case ConditionType.WAIT:
          setModalContent(
            <WaitEditor data={node.data} onCancel={onCancel} onSave={onSave} />
          );
          break;
        case ConditionType.WAIT_UNTIL:
          setModalContent(
            <WaitUntilEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
      }
    } else if (nodeType === StepType.EVENT) {
      switch (componentType) {
        case EventType.OPEN_EMAIL:
          setModalContent(
            <OpenEmailEditor
              data={node.data}
              onCancel={onCancel}
              onSave={onSave}
            />
          );
          break;
      }
    }
  }

  useEffect(() => {
    if (node != null) {
      onSelectItem(node.type, node.component);
    }
  }, [node]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          minWidth: "800px",
          borderRadius: "0.8rem",
          boxShadow: 24,
        }}
      >
        <div
          id="modal-modal-title"
          style={{
            padding: "0.8rem",
          }}
        >
          {editMode ? "Edit" : "Add New"} Action
          <Button onClick={handleClose}>
            <Icon>close</Icon>
          </Button>
        </div>
        <div id="modal-modal-description">
          {modalContent == null ? (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div style={{ backgroundColor: "#4e5468", marginRight: "8px" }}>
                  {categories.map((category, i) => (
                    <button
                      key={category.name}
                      onClick={() => {
                        setSelectedCategoryIndex(i);
                      }}
                      style={{
                        width: "100%",
                        padding: "1rem",
                        direction: "ltr",
                        fontSize: "14px",
                        lineHeight: "14px",
                        color: "#fff",
                        margin: 0,
                        cursor: "pointer",
                        textAlign: "left",
                        position: "relative",
                        border: "1px solid #353a4a",
                        backgroundColor:
                          selectedCategoryIndex === i
                            ? "#353a4a"
                            : "transparent",
                        fontWeight: 600,
                      }}
                    >
                      {category.name}
                    </button>
                  ))}
                </div>
              </Grid>
              <Grid item xs={8}>
                {categories[selectedCategoryIndex].components.map(
                  (component) => (
                    <Item
                      key={component.name}
                      title={component.title}
                      description={component.description}
                      backgroundColor={component.backgroundColor}
                      icon={component.icon}
                      onClick={() =>
                        onSelectItem(
                          component.nodeType,
                          component.componentType
                        )
                      }
                    />
                  )
                )}
              </Grid>
            </Grid>
          ) : (
            <div style={{ padding: "1rem" }}>{modalContent}</div>
          )}
        </div>
      </Box>
    </Modal>
  );
}
