import { Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { findListId } from "repositories/list-repository";
import { DeleteButton } from "./delete-button.component";

export function ContactSubscribeList({ style, data, onEdit, onDelete }) {
  const [list, setList] = useState(null);

  useEffect(() => {
    findListId(data.listId).then((result) => setList(result.data));
  }, [data]);

  return (
    <button type="button" style={style}>
      <div>
        <Icon style={{ paddingTop: "4px", marginRight: "4px" }}>person</Icon>
        <span>Subscribe List Trigger</span>
        <br />
        <span style={{ fontSize: "0.8rem" }}>
          Contact subscribe to list "{list?.name}"
        </span>
      </div>
      <DeleteButton onEdit={onEdit} onDelete={onDelete} />
    </button>
  );
}
