import { Grid, Icon } from "@mui/material";
import { Operator, SegmentType } from "./enums";
import { DeleteButton } from "./delete-button.component";
import { AddYesNo } from "./yes-no.component";

export function IfElseComponent({ style, data, onEdit, onDelete }) {
  return (
    <div>
      <button type="button" style={style}>
        <div>
          <Icon
            style={{
              paddingTop: "4px",
              marginRight: "4px",
            }}
          >
            access_time
          </Icon>
          <span>Does the contact match the following conditions?</span>
          <span style={{ fontSize: "0.8rem" }}>
            {data.segments.map((segment, i) => {
              const operator = Object.keys(Operator).find(
                (key) => Operator[key] === segment.operation.operator
              );

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <div>
                    {segment.operation.source} {segment.operation.property}{" "}
                    {operator.replace(/_/g, " ")} "{segment.operation.value}"
                  </div>
                  {i < data.segments.length - 1 ? (
                    <div style={{ fontWeight: "bold" }}>
                      {segment.segmentType == SegmentType.AND ? "AND" : "OR"}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </span>
        </div>
        <DeleteButton onEdit={onEdit} onDelete={onDelete} />
      </button>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AddYesNo label="Yes" color="green" onClick={() => {}} />
        </Grid>
        <Grid item xs={6}>
          <AddYesNo label="No" color="red" onClick={() => {}} />
        </Grid>
      </Grid>
    </div>
  );
}
