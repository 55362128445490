import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { Link, useNavigate } from "react-router-dom";
import { MDButton } from "components/MDButton";
import {
  Box,
  CircularProgress,
  Icon,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { ActionTableComp } from "components/ActionTableComp";
import { deleteWebpush, findWebpush } from "repositories/webpush-repository";

export function WebPushList() {
  const [total, setTotal] = useState(0);
  const [webpushList, setWebpushList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPageOptions = [10, 25, 50, 100, 500, 1000];
  const navigate = useNavigate();

  function fetchData(pageIndex) {
    setIsLoading(true);
    findWebpush({}, (pageIndex - 1) * itemsPerPage, itemsPerPage)
      .then((result) => {
        const [data, count] = result.data;
        setWebpushList(data);
        setTotal(count);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, itemsPerPage]);

  function onEdit(contact) {
    navigate(`/webpush/${contact._id}`, { replace: true });
  }

  function onDelete(webpush) {
    deleteWebpush(webpush._id).then(() => {
      setWebpushList(webpushList.filter((_) => _._id !== webpush._id));
    });
  }

  function handlePageChange(event, value) {
    setCurrentPage(value);
  }

  function onCopyCode(webpush) {
    const link = `//bucket.autcamp.com/${webpush._id}.js`;

    navigator.clipboard.writeText(`<script async src="${link}"></script>`);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Webpushs
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Link to="/webpush/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;add new
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <MDBox component="thead">
                      <TableRow>
                        <DataTableHeadCell width="auto" align="left">
                          Name
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Subject
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Actions
                        </DataTableHeadCell>
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {webpushList.map((webpush) => (
                        <TableRow key={webpush._id}>
                          <TableCell>{webpush.name}</TableCell>
                          <TableCell>{webpush.subject}</TableCell>
                          <TableCell>
                            <ActionTableComp
                              onDelete={() => onDelete(webpush)}
                              onEdit={() => onEdit(webpush)}
                            >
                              <MenuItem onClick={() => onCopyCode(webpush)}>
                                Copy Code
                              </MenuItem>
                            </ActionTableComp>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <MDBox
                    pt={3}
                    mx={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <MDTypography variant="caption" mr={1}>
                      Items per page:
                    </MDTypography>
                    <select
                      value={itemsPerPage}
                      onChange={(event) =>
                        setItemsPerPage(Number(event.target.value))
                      }
                    >
                      {itemsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </MDBox>
                  <MDBox pt={3} display="flex" justifyContent="space-between">
                    <MDTypography variant="caption">
                      Page {currentPage} of {Math.ceil(total / itemsPerPage)}
                    </MDTypography>
                    <Pagination
                      count={Math.ceil(total / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="secondary"
                      size="large"
                    />
                  </MDBox>
                  {isLoading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      marginBottom="3rem"
                    >
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : null}
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
