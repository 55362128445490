import { cache } from "layouts/services/cache-service";
import { client } from "./client";
import { getLoggedUser } from "./user-utils";

const path = "users";

export async function getUserCompanies() {
  const userId = getLoggedUser()._id;

  return client.get(`${path}/${userId}/companies`);
}

export async function login(email, password) {
  return client
    .post(
      `${path}/login`,
      {
        email,
        password,
      },
      {
        headers: {
          // "g-recaptcha-response": "hgf",
        },
      }
    )
    .then(async (result) => {
      client.defaults.headers.common.Authorization = result.data.token;
      localStorage.setItem("user", JSON.stringify(result.data));
      const b = await getUserCompanies();
      localStorage.setItem("companyId", b.data[0]._id);
      return result.data;
    });
}

export const findUserById = cache("findUserById", (userId) =>
  client.get(`${path}/${userId}`)
);

export const findUsers = cache("findUsers", () =>
  client.get(path, {
    params: { companyIds: localStorage.getItem("companyId") },
  })
);
