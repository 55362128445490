import { Icon } from "@mui/material";

export function AddNewNode({ onClick }) {
  const lineStyle = {
    content: "",
    display: "block",
    height: "21px",
    width: 0,
    borderRight: "2px solid black",
    margin: "auto",
    bottom: "100%",
    left: "50%",
  };

  return (
    <div>
      <div style={lineStyle} />
      <button
        type="button"
        style={{
          marginLeft: "2px",
          boxSizing: "border-box",
          position: "relative",
          display: "flex",
          width: "28px",
          height: "28px",
          lineHeight: "26px",
          borderRadius: "50%",
          fontSize: "8px",
          textAlign: "center",
          background: "#fff",
          border: "2px solid",
          boxShadow: "0 4px 4px 0 rgb(0 0 0 / 13%)",
          margin: "auto",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <Icon
          sx={{
            fontWeight: "bold",
            fontSize: "1rem !important",
            margin: "auto",
          }}
        >
          add
        </Icon>
      </button>
      <div style={lineStyle} />
    </div>
  );
}
