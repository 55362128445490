export const StepType = {
  END: 0,
  TRIGGER: 1,
  ACTION: 2,
  CONDITION: 3,
  EVENT: 4,
};

export const ActionType = {
  REMOVE_FROM_LIST: 0,
  SEND_EMAIL: 1,
  UNSUBSCRIBE: 2,
  SEND_SMS: 3,
  SEND_WEBPUSH: 4,
};

export const ConditionType = {
  HOUR_INTERVAL: 0,
  WAIT: 1,
  IF_ELSE: 2,
  WAIT_UNTIL: 3,
};

export const EventType = {
  OPEN_EMAIL: 0,
};

export const TriggerType = {
  CONTACT_SUBSCRIBE_LIST: 0,
  CONTACT_CREATE: 1,
  WEBPUSH_SUBSCRIBE: 2,
};

export const SegmentType = {
  AND: 0,
  OR: 1,
};

export const Operator = {
  IS: 0,
  IS_NOT: 1,
  CONTAINS: 2,
  DOES_NOT_CONTAIN: 3,
  EXISTS: 4,
  DOES_NOT_EXIST: 5,
  GREATER_THAN: 6,
  GREATER_THAN_OR_EQUAL_TO: 7,
  LESS_THAN: 8,
  LESS_THAN_OR_EQUAL_TO: 9,
  BLANK: 10,
  NOT_BLANK: 11,
};
