import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "automations";

export async function createAutomation(companyId, name, description, nodes) {
  return client.post(path, {
    companyId,
    name,
    description,
    nodes,
  });
}

export async function updateAutomation(id, name, description, nodes) {
  return client.patch(`${path}/${id}`, {
    name,
    description,
    nodes,
  });
}

export async function findAutomation(name) {
  return client.get(path, {
    params: {
      companyId: localStorage.getItem("companyId"),
      name,
    },
  });
}

export const findAutomationById = cache("findAutomationById", (automationId) =>
  client.get(`${path}/${automationId}`)
);

export function deleteAutomation(automationId) {
  return client.delete(`${path}/${automationId}`);
}
