import { ContactCreateTrigger } from "./contact-create.component";
import { ContactSubscribeList } from "./contact-subcribe-list.component";
import { DeleteButton } from "./delete-button.component";
import { OpenEmail } from "./open-email.component";
import { RemoveList } from "./remove-list.component";
import { SendEmail } from "./send-email.component";
import { SendSms } from "./send-sms.component";
import {
  StepType,
  ActionType,
  TriggerType,
  EventType,
  ConditionType,
} from "./enums";
import { WaitUntilComponent } from "./wait-until.component";
import { WaitComponent } from "./wait.component";
import { IfElseComponent } from "./if-else.component";
import { WebpushSubscribeTrigger } from "./webpush-subscribe.component";
import { SendWebpush } from "./send-webpush.component";

export function Block({ node, onSelectNode, onDelete }) {
  const defaultStyle = {
    border: "1px solid black",
    padding: "0.5rem",
    fontSize: "1rem",
    borderRadius: "0.7rem",
    textAlign: "center",
    boxShadow: "rgb(0 0 0 / 20%) 1px 4px 12px 1px",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  };

  if (node.type === StepType.END) {
    return (
      <div
        style={{
          ...defaultStyle,
          border: "1rem",
          borderRadius: "0.6rem",
          color: "white",
          backgroundColor: "#a54343",
        }}
      >
        End
      </div>
    );
  }
  if (node.type === StepType.ACTION) {
    if (node.component === ActionType.UNSUBSCRIBE) {
      return (
        <button type="button" style={defaultStyle} onClick={onSelectNode}>
          Unsubscribe
          <DeleteButton onDelete={onDelete} />
        </button>
      );
    }
    if (node.component === ActionType.SEND_EMAIL) {
      return (
        <SendEmail
          style={defaultStyle}
          data={node.data}
          onEdit={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
    if (node.component === ActionType.SEND_WEBPUSH) {
      return (
        <SendWebpush
          style={defaultStyle}
          data={node.data}
          onEdit={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
    if (node.component === ActionType.SEND_SMS) {
      return (
        <SendSms
          style={defaultStyle}
          data={node.data}
          onEdit={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
    if (node.component === ActionType.REMOVE_FROM_LIST) {
      return (
        <RemoveList
          style={defaultStyle}
          data={node.data}
          onEdit={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
  }
  if (node.type === StepType.TRIGGER) {
    if (node.component === TriggerType.CONTACT_SUBSCRIBE_LIST) {
      return (
        <ContactSubscribeList
          style={{ ...defaultStyle, width: "auto" }}
          data={node.data}
          onEdit={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
    if (node.component === TriggerType.CONTACT_CREATE) {
      return (
        <ContactCreateTrigger
          style={{ ...defaultStyle, width: "auto" }}
          onDelete={onDelete}
        />
      );
    }
    if (node.component === TriggerType.WEBPUSH_SUBSCRIBE) {
      return (
        <WebpushSubscribeTrigger
          style={{ ...defaultStyle, width: "auto" }}
          onDelete={onDelete}
        />
      );
    }
  }
  if (node.type === StepType.EVENT) {
    if (node.component === EventType.OPEN_EMAIL) {
      return (
        <OpenEmail
          style={defaultStyle}
          data={node.data}
          onEdit={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
  }
  if (node.type === StepType.CONDITION) {
    if (node.component === ConditionType.WAIT) {
      return (
        <WaitComponent
          style={defaultStyle}
          data={node.data}
          onEdit={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
    if (node.component === ConditionType.WAIT_UNTIL) {
      return (
        <WaitUntilComponent
          style={defaultStyle}
          data={node.data}
          onClick={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
    if (node.component === ConditionType.IF_ELSE) {
      return (
        <IfElseComponent
          style={defaultStyle}
          data={node.data}
          onEdit={onSelectNode}
          onDelete={onDelete}
        />
      );
    }
  }

  return <button type="button" style={defaultStyle} onClick={onSelectNode} />;
}
