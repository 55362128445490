import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MDButton } from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createContact,
  findContactById,
  updateContact,
} from "repositories/contact-repository";
import { FormValidationError } from "layouts/authentication/components/form-validation-error";

export function ContactCreate() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(null);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const companyId = localStorage.getItem("companyId");
  const { id } = useParams();

  useEffect(() => {
    if (id != null)
      findContactById(id).then(({ data }) => {
        setFullname(data.fullname);
        setEmail(data.email);
        setPhone(data.phone);
      });
  }, []);

  function onCreate() {
    if (id == null) {
      createContact(companyId, email, phone, fullname)
        .then(() => {
          navigate("/contacts", { replace: true });
        })
        .catch((error) => {
          setValidationError(error.message);
        });
    } else {
      updateContact(id, email, phone, fullname).then(() => {
        navigate("/contacts", { replace: true });
      });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      {id == null ? 'Create New' : 'Update'} Contact
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} mx={2} mb={2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                      error={fullname.length === 0}
                      helperText={!fullname.length ? "name is required" : ""}
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={email.length === 0}
                      helperText={!email.length ? "email is required" : ""}
                      type="email"
                      label="Email"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      error={phone.length === 0}
                      helperText={!phone.length ? "phone is required" : ""}
                      type="phone"
                      label="Phone"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <FormValidationError
                    error={validationError}
                    onDismiss={() => {
                      setValidationError(null);
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="white"
                        fullWidth
                        onClick={() => navigate("/contacts", { replace: true })}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={onCreate}
                      >
                        {id == null ? 'Create' : 'Update'}
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
