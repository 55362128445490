import { Grid } from "@mui/material";
import { MDButton } from "components/MDButton";
import { useEffect, useState } from "react";
import { findLists } from "repositories/list-repository";

export function UnsubscribeListEditor({ data, onCancel, onSave }) {
  const [listId, setListId] = useState(data.listId || "");
  const [lists, setLists] = useState([]);

  useEffect(() => {
    findLists().then((result) => {
      if (result.data.length > 0) {
        setLists(result.data);
        setListId(result.data[0]._id);
      }
    });
  }, []);

  function onCreate() {
    onSave({
      listId,
    });
  }

  return (
    <div>
      <div>Select a list</div>
      <select
        value={listId}
        onChange={(e) => setListId(e.target.value)}
        placeholder="Select a list"
      >
        {lists.map((item) => (
          <option key={item._id} value={item._id}>
            {item.name}
          </option>
        ))}
      </select>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="white"
            fullWidth
            onClick={onCancel}
          >
            Cancel
          </MDButton>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={onCreate}
          >
            Create
          </MDButton>
        </Grid>
      </Grid>
    </div>
  );
}
