import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { MDButton } from "components/MDButton";
import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";
import { deleteList, findLists } from "repositories/list-repository";
import { ActionTableComp } from "components/ActionTableComp";

export function ListView() {
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    findLists()
      .then((result) => {
        setLists(result.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const columns = [
    { Header: "name", accessor: "name", width: "45%", align: "left" },
    {
      Header: "description",
      accessor: "description",
      width: "45%",
      align: "left",
    },
    { Header: "modified at", accessor: "updatedAt", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  function onEdit(list) {
    window.open(`/lists/${list._id}`, "_blank");
  }

  function onDelete(list) {
    deleteList(list._id).then(() => {
      setLists(lists.filter((_) => _._id != list._id));
    });
  }

  const rows = lists.map((list) => ({
    name: <div>{list.name}</div>,
    description: <div>{list.description}</div>,
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={list.status}
          color="success"
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    updatedAt: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {list.updatedAt.split("T")[0]}
      </MDTypography>
    ),
    action: (
      <ActionTableComp
        onDelete={() => onDelete(list)}
        onEdit={() => onEdit(list)}
      />
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Lists
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Link to="/lists/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;add new
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isLoading={isLoading}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
