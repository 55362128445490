import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import { MDButton } from "components/MDButton";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteIntegration,
  findIntegrations,
} from "repositories/integration-repository";
import { ActionTableComp } from "components/ActionTableComp";
import { IntegrationType, ServiceType } from "./enums";

function EmailPrev({ name, description }) {
  return (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {name}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );
}

export function IntegrationList() {
  const navigate = useNavigate();
  const [integrations, setIntegrations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    findIntegrations()
      .then((result) => {
        setIntegrations(result.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  function onEdit(integration) {
    navigate(`/integrations/${integration._id}`, { replace: true });
  }

  function onDelete(integration) {
    deleteIntegration(integration._id).then(() => {
      setIntegrations(integrations.filter((_) => _._id != integration._id));
    });
  }

  function getKeyByValue(object, value) {
    return Object.keys(object)
      .find((key) => object[key] === value)
      .replace(/_/g, " ");
  }

  const columns = [
    { Header: "name", accessor: "name", width: "45%", align: "left" },
    { Header: "type", accessor: "type", width: "45%", align: "left" },
    {
      Header: "service type",
      accessor: "serviceType",
      width: "45%",
      align: "left",
    },
    { Header: "modified at", accessor: "updatedAt", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = integrations.map((integration) => ({
    name: (
      <EmailPrev
        name={integration.name}
        description={integration.description}
      />
    ),
    type: <div>{getKeyByValue(IntegrationType, integration.type)}</div>,
    serviceType: (
      <div>{getKeyByValue(ServiceType, integration.serviceType)}</div>
    ),
    updatedAt: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {integration.updatedAt.split("T")[0]}
      </MDTypography>
    ),
    action: (
      <ActionTableComp
        onDelete={() => onDelete(integration)}
        onEdit={() => onEdit(integration)}
      />
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Integrations
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Link to="/integrations/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;add new
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isLoading={isLoading}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
