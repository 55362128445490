import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { MDButton } from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import {
  createEmail,
  findEmailById,
  updateEmail,
} from "repositories/email-repository";
import { FormValidationError } from "layouts/authentication/components/form-validation-error";
import { useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";

export function EmailCreate() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [from, setFrom] = useState("");
  const [html, setHtml] = useState("");
  const companyId = localStorage.getItem("companyId");
  const { id } = useParams();

  useEffect(() => {
    if (id != null)
      findEmailById(id).then(({ data }) => {
        setName(data.name);
        setDescription(data.description);
        setSubject(data.subject);
        setFrom(data.from || "");
        setHtml(data.html);
      });
  }, []);

  function onCreate() {
    if (id == null) {
      createEmail(companyId, name, description, subject, from, html)
        .then(() => {
          navigate("/emails", { replace: true });
        })
        .catch((error) => {
          setValidationError(error.message);
        });
    } else {
      updateEmail(id, name, description, subject, from, html).then(() => {
        navigate("/emails", { replace: true });
      });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      {id == null ? 'Create New' : 'Update'} Email
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDButton variant="gradient" color="dark">
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;add new
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} mx={2} mb={2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={name.length === 0}
                      helperText={!name.length ? "name is required" : ""}
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      error={description.length === 0}
                      helperText={
                        !description.length ? "description is required" : ""
                      }
                      type="text"
                      label="Description"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      error={subject.length === 0}
                      helperText={!subject.length ? "subject is required" : ""}
                      type="text"
                      label="Subject"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                      error={from.length === 0}
                      helperText={!from.length ? "from is required" : ""}
                      type="text"
                      label="From"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={html}
                      onChange={(e) => setHtml(e.target.value)}
                      error={html.length === 0}
                      helperText={!html.length ? "html is required" : ""}
                      type="text"
                      label="HTML"
                      multiline
                      rows={12}
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <FormValidationError
                    error={validationError}
                    onDismiss={() => {
                      setValidationError(null);
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="white"
                        fullWidth
                        onClick={() => navigate("/emails", { replace: true })}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={onCreate}
                      >
                        {id == null ? 'Create' : 'Update'}
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
