import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { MDButton } from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { FormValidationError } from "layouts/authentication/components/form-validation-error";
import { useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import {
  createWebpush,
  findWebpushById,
  updateWebpush,
} from "repositories/webpush-repository";
import Switch from "@mui/material/Switch";  // Adicione essa linha

export function WebpushCreate() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(null);
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");

  const [bannerEnabled, setBannerEnabled] = useState(true); // Adicione essa linha

  const [title, setTitle] = useState("");
  const [titleSize, setTitleSize] = useState("14px");
  const [titleFontFamily, setTitleFontFamily] = useState("consolas, sans-serif");
  const [titleColor, setTitleColor] = useState("#000000");
  const [bannerBackgroundColor, setBannerBackgroundColor] = useState("#ffffff");
  const [cancelButtonText, setCancelButtonText] = useState("Deny");
  const [cancelButtonTextColor, setCancelButtonTextColor] = useState("#000000");
  const [cancelButtonFontSize, setCancelButtonFontSize] = useState("14px");
  const [cancelButtonFontFamily, setCancelButtonFontFamily] = useState("consolas, sans-serif");
  const [cancelButtonBackgroundColor, setCancelButtonBackgroundColor] = useState("#C9C9C9");
  const [allowButtonText, setAllowButtonText] = useState("Allow");
  const [allowButtonTextColor, setAllowButtonTextColor] = useState("#000000");
  const [allowButtonFontSize, setAllowButtonFontSize] = useState("14px");
  const [allowButtonFontFamily, setAllowButtonFontFamily] = useState("consolas, sans-serif");
  const [allowButtonBackgroundColor, setAllowButtonBackgroundColor] = useState("#3891ee");

  const companyId = localStorage.getItem("companyId");
  const { id } = useParams();

  useEffect(() => {
    if (id != null)
      findWebpushById(id).then(({ data }) => {
        setName(data.name);
        setSubject(data.subject);

        setBannerEnabled(data.banner != null);

        if (data.banner) {
          setTitle(data.banner.title);
          setTitleSize(data.banner.titleSize);
          setTitleFontFamily(data.banner.titleFontFamily);
          setTitleColor(data.banner.titleColor);
          setBannerBackgroundColor(data.banner.backgroundColor);
          setCancelButtonText(data.banner.cancelButton.text);
          setCancelButtonTextColor(data.banner.cancelButton.textColor);
          setCancelButtonFontSize(data.banner.cancelButton.fontSize);
          setCancelButtonFontFamily(data.banner.cancelButton.fontFamily);
          setCancelButtonBackgroundColor(data.banner.cancelButton.backgroundColor);
          setAllowButtonText(data.banner.allowButton.text);
          setAllowButtonTextColor(data.banner.allowButton.textColor);
          setAllowButtonFontSize(data.banner.allowButton.fontSize);
          setAllowButtonFontFamily(data.banner.allowButton.fontFamily);
          setAllowButtonBackgroundColor(data.banner.allowButton.backgroundColor);
        }
      });
  }, [id]);

  function onCreate() {
    const payload = {
      name,
      subject,
      banner: bannerEnabled ? {
        title,
        titleSize,
        titleFontFamily,
        titleColor,
        backgroundColor: bannerBackgroundColor,
        cancelButton: {
          text: cancelButtonText,
          textColor: cancelButtonTextColor,
          fontSize: cancelButtonFontSize,
          fontFamily: cancelButtonFontFamily,
          backgroundColor: cancelButtonBackgroundColor,
        },
        allowButton: {
          text: allowButtonText,
          textColor: allowButtonTextColor,
          fontSize: allowButtonFontSize,
          fontFamily: allowButtonFontFamily,
          backgroundColor: allowButtonBackgroundColor,
        },
      } : undefined,
    };
    if (id == null) {
      createWebpush(companyId, payload)
        .then(() => {
          navigate("/webpush", { replace: true });
        })
        .catch((error) => {
          setValidationError(error.message);
        });
    } else {
      updateWebpush(id, payload).then(() => {
        navigate("/webpush", { replace: true });
      });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      {id == null ? 'Create New' : 'Update'} Webpush
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDButton variant="gradient" color="dark">
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;add new
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} mx={2} mb={2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={name.length === 0}
                      helperText={!name.length ? "name is required" : ""}
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      error={subject.length === 0}
                      helperText={!subject.length ? "subject is required" : ""}
                      type="text"
                      label="Subject"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <Grid container alignItems="center" mb={2}>
                    <Grid item>
                      <MDTypography>Show Banner Options</MDTypography>
                    </Grid>
                    <Grid item>
                      <Switch checked={bannerEnabled} onChange={() => setBannerEnabled(!bannerEnabled)} />
                    </Grid>
                  </Grid>
                  {bannerEnabled && (
                    <>
                      <span>Banner</span>
                      <MDBox mb={2}>
                        <MDInput
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          error={title.length === 0}
                          helperText={!title.length ? "Title is required" : ""}
                          type="text"
                          label="Banner Title"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={titleSize}
                          onChange={(e) => setTitleSize(e.target.value)}
                          error={titleSize.length === 0}
                          helperText={!titleSize.length ? "Title size is required" : ""}
                          type="text"
                          label="Title Size"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={titleFontFamily}
                          onChange={(e) => setTitleFontFamily(e.target.value)}
                          error={titleFontFamily.length === 0}
                          helperText={!titleFontFamily.length ? "Font family is required" : ""}
                          type="text"
                          label="Title Font Family"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={titleColor}
                          onChange={(e) => setTitleColor(e.target.value)}
                          error={titleColor.length === 0}
                          helperText={!titleColor.length ? "Title color is required" : ""}
                          type="color"
                          label="Title Color"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={bannerBackgroundColor}
                          onChange={(e) => setBannerBackgroundColor(e.target.value)}
                          error={bannerBackgroundColor.length === 0}
                          helperText={!bannerBackgroundColor.length ? "Background color is required" : ""}
                          type="color"
                          label="Banner Background Color"
                          fullWidth
                        />
                      </MDBox>
                      <span>Cancel Button</span>
                      <MDBox mb={2}>
                        <MDInput
                          value={cancelButtonText}
                          onChange={(e) => setCancelButtonText(e.target.value)}
                          error={cancelButtonText.length === 0}
                          helperText={!cancelButtonText.length ? "Cancel button text is required" : ""}
                          type="text"
                          label="Text"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={cancelButtonFontSize}
                          onChange={(e) => setCancelButtonFontSize(e.target.value)}
                          error={cancelButtonFontSize.length === 0}
                          helperText={!cancelButtonFontSize.length ? "Cancel button font size is required" : ""}
                          type="text"
                          label="Font Size"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={cancelButtonFontFamily}
                          onChange={(e) => setCancelButtonFontFamily(e.target.value)}
                          error={cancelButtonFontFamily.length === 0}
                          helperText={!cancelButtonFontFamily.length ? "Cancel button font family is required" : ""}
                          type="text"
                          label="Font Family"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={cancelButtonTextColor}
                          onChange={(e) => setCancelButtonTextColor(e.target.value)}
                          error={cancelButtonTextColor.length === 0}
                          helperText={!cancelButtonTextColor.length ? "Cancel button text color is required" : ""}
                          type="color"
                          label="Text Color"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={cancelButtonBackgroundColor}
                          onChange={(e) => setCancelButtonBackgroundColor(e.target.value)}
                          error={cancelButtonBackgroundColor.length === 0}
                          helperText={!cancelButtonBackgroundColor.length ? "Cancel button background color is required" : ""}
                          type="color"
                          label="Background Color"
                          fullWidth
                        />
                      </MDBox>
                      <span>Allow Button</span>
                      <MDBox mb={2}>
                        <MDInput
                          value={allowButtonText}
                          onChange={(e) => setAllowButtonText(e.target.value)}
                          error={allowButtonText.length === 0}
                          helperText={!allowButtonText.length ? "Allow button text is required" : ""}
                          type="text"
                          label="Text"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={allowButtonFontSize}
                          onChange={(e) => setAllowButtonFontSize(e.target.value)}
                          error={allowButtonFontSize.length === 0}
                          helperText={!allowButtonFontSize.length ? "Allow button font size is required" : ""}
                          type="text"
                          label="Font Size"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={allowButtonFontFamily}
                          onChange={(e) => setAllowButtonFontFamily(e.target.value)}
                          error={allowButtonFontFamily.length === 0}
                          helperText={!allowButtonFontFamily.length ? "Allow button font family is required" : ""}
                          type="text"
                          label="Font Family"
                          variant="standard"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={allowButtonTextColor}
                          onChange={(e) => setAllowButtonTextColor(e.target.value)}
                          error={allowButtonTextColor.length === 0}
                          helperText={!allowButtonTextColor.length ? "Allow button text color is required" : ""}
                          type="color"
                          label="Text Color"
                          fullWidth
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          value={allowButtonBackgroundColor}
                          onChange={(e) => setAllowButtonBackgroundColor(e.target.value)}
                          error={allowButtonBackgroundColor.length === 0}
                          helperText={!allowButtonBackgroundColor.length ? "Allow button background color is required" : ""}
                          type="color"
                          label="Background Color"
                          fullWidth
                        />
                      </MDBox>
                    </>
                  )}
                  <FormValidationError
                    error={validationError}
                    onDismiss={() => {
                      setValidationError(null);
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="white"
                        fullWidth
                        onClick={() => navigate("/webpush", { replace: true })}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={onCreate}
                      >
                        {id == null ? 'Create' : 'Update'}
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
