import { Grid } from "@mui/material";
import { MDButton } from "components/MDButton";
import { useEffect, useState } from "react";
import { findEmails } from "repositories/email-repository";

export function OpenEmailEditor({ data, onCancel, onSave }) {
  const [emailId, setEmailId] = useState(data.emailId || null);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    findEmails().then((result) => {
      if (result.data.length > 0) {
        setEmails(result.data);
      }
    });
  }, []);

  function onCreate() {
    onSave({
      emailId,
    });
  }

  return (
    <div>
      <div>Select a email</div>
      <select
        value={emailId}
        onChange={(e) => setEmailId(e.target.value)}
        placeholder="Select an email"
      >
        <option value={null}>Any Email</option>
        {emails.map((item) => (
          <option key={item._id} value={item._id}>
            {item.name}
          </option>
        ))}
      </select>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="white"
            fullWidth
            onClick={onCancel}
          >
            Cancel
          </MDButton>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={onCreate}
          >
            Create
          </MDButton>
        </Grid>
      </Grid>
    </div>
  );
}
