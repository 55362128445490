import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "webpush/messages";

export async function createWebpushMessage(name, payload) {
  return client.post(path, {
    companyId: localStorage.getItem("companyId"),
    name,
    payload,
  });
}

export async function updateWebpushMessage(id, name, payload) {
  return client.patch(`${path}/${id}`, {
    companyId: localStorage.getItem("companyId"),
    name,
    payload,
  });
}

export const findWebpushMessages = cache(
  "findWebpushMessages",
  (filter, skip, limit) =>
    client.get(path, {
      params: {
        filter: JSON.stringify({
          ...filter,
          companyId: localStorage.getItem("companyId"),
        }),
        skip,
        limit,
      },
    })
);

export function deleteWebpushMessageById(id) {
  return client.delete(`${path}/${id}`);
}

export const findWebpushMessageById = cache("findWebpushMessageById", (id) =>
  client.get(`${path}/${id}`)
);
