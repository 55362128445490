import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "integrations";

export async function createIntegration(
  name,
  description,
  companyId,
  type,
  serviceType,
  data
) {
  return client.post(path, {
    name,
    description,
    companyId,
    type,
    serviceType,
    data,
  });
}

export async function updateIntegration(
  id,
  name,
  description,
  type,
  serviceType,
  data
) {
  return client.patch(`${path}/${id}`, {
    name,
    description,
    type,
    serviceType,
    data,
  });
}

export const findIntegrations = cache("findIntegrations", (name) =>
  client.get(path, {
    params: {
      companyId: localStorage.getItem("companyId"),
      name,
    },
  })
);

export function deleteIntegration(integrationId) {
  return client.delete(`${path}/${integrationId}`);
}

export const findIntegrationById = cache(
  "findIntegrationById",
  (integrationId) => client.get(`${path}/${integrationId}`)
);
