import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "companies";

export async function register(name, email, phone, password) {
  return client.post(path, {
    name,
    email,
    phone,
    password,
  });
}

export const findCompanyById = cache("findEmailById", (companyIdId) =>
  client.get(`${path}/${companyIdId}`)
);
