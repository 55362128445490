import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MDButton } from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { FormValidationError } from "layouts/authentication/components/form-validation-error";
import { useNavigate, useParams } from "react-router-dom";
import MDInput from "components/MDInput";
import { createSms, findSmsById, updateSms } from "repositories/sms-repository";

export function SmsCreate() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [body, setBody] = useState("");
  const companyId = localStorage.getItem("companyId");
  const { id } = useParams();

  useEffect(() => {
    findSmsById(id).then(({ data }) => {
      setName(data.name);
      setDescription(data.description);
      setBody(data.body);
    });
  }, []);

  function onCreate() {
    if (id == null) {
      createSms(companyId, name, description, body)
        .then(() => {
          navigate("/sms", { replace: true });
        })
        .catch((error) => {
          setValidationError(error.message);
        });
    } else {
      updateSms(id, name, description, body);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      {id == null ? 'Create New' : 'Update'} SMS
                    </MDTypography>
                  </Grid>
                  <Grid item />
                </Grid>
              </MDBox>
              <MDBox pt={3} mx={2} mb={2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={name.length === 0}
                      helperText={!name.length ? "name is required" : ""}
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      error={description.length === 0}
                      helperText={
                        !description.length ? "description is required" : ""
                      }
                      type="text"
                      label="Description"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      error={body.length === 0}
                      helperText={!body.length ? "body is required" : ""}
                      type="text"
                      label="Body"
                      multiline
                      rows={12}
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <FormValidationError
                    error={validationError}
                    onDismiss={() => {
                      setValidationError(null);
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="white"
                        fullWidth
                        onClick={() => navigate("/sms", { replace: true })}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={onCreate}
                      >
                        {id == null ? 'Create' : 'Update'}
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
