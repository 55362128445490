export function AddYesNo({ label, color, onClick }) {
  return (
    <div>
      <button
        type="button"
        style={{
          marginLeft: "2px",
          boxSizing: "border-box",
          position: "relative",
          display: "flex",
          width: "38px",
          height: "38px",
          lineHeight: "26px",
          borderRadius: "50%",
          fontSize: "12px",
          textAlign: "center",
          background: "#fff",
          border: "2px solid",
          boxShadow: "0 4px 4px 0 rgb(0 0 0 / 13%)",
          color,
          fontFamily: "bold",
          borderColor: color,
          margin: "auto",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <span style={{ margin: "auto" }}>{label}</span>
      </button>
    </div>
  );
}
