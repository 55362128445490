import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "contacts";

export async function createContact(companyId, email, phone, fullname) {
  return client.post(path, {
    companyId,
    email,
    phone,
    fullname,
  });
}

export async function updateContact(id, email, phone, fullname) {
  return client.patch(`${path}/${id}`, {
    email,
    phone,
    fullname,
  });
}

export async function findContacts({ email, phone, fullname }, skip, limit) {
  return client.get(path, {
    params: {
      filter: JSON.stringify({
        companyId: localStorage.getItem("companyId"),
        email,
        phone,
        fullname,
      }),
      skip,
      limit,
    },
  });
}

export const findContactById = cache("findContactById", (contactId) =>
  client.get(`${path}/${contactId}`)
);

export function deleteContact(contactId) {
  return client.delete(`${path}/${contactId}`);
}
