import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { Link } from "react-router-dom";
import { MDButton } from "components/MDButton";
import {
  Box,
  CircularProgress,
  Icon,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { ActionTableComp } from "components/ActionTableComp";
import {
  deleteWebpushMessageById,
  findWebpushMessages,
} from "repositories/webpush-message-repository";

function percent(a, b) {
  const result =
    b === null || b === 0 ? "0" : ((a / b) * 100).toFixed(1).replace(".0", "");
  return ` (${result}%)`;
}

export function WebpushMessageList() {
  const [total, setTotal] = useState(0);
  const [webpushMessages, setWebpushMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPageOptions = [10, 25, 50, 100, 500, 1000];

  function fetchData(pageIndex) {
    setIsLoading(true);
    findWebpushMessages({}, (pageIndex - 1) * itemsPerPage, itemsPerPage)
      .then((result) => {
        const [data, count] = result.data;
        setWebpushMessages(data);
        setTotal(count);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, itemsPerPage]);

  function onEdit(contact) {
    window.open(`/webpush/messages/${contact._id}`, "_blank");
  }

  function onDelete(message) {
    deleteWebpushMessageById(message._id).then(() => {
      setWebpushMessages(webpushMessages.filter((_) => _._id !== message._id));
    });
  }

  function handlePageChange(event, value) {
    setCurrentPage(value);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Webpush Messages
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Link to="/webpush/messages/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;add new
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <MDBox component="thead">
                      <TableRow>
                        <DataTableHeadCell width="auto" align="left">
                          Name
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Title
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Message
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Sent
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Opens (%)
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Clicks (%)
                        </DataTableHeadCell>
                        <DataTableHeadCell width="auto" align="left">
                          Actions
                        </DataTableHeadCell>
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {webpushMessages.map((message) => (
                        <TableRow key={message._id}>
                          <TableCell>{message.name}</TableCell>
                          <TableCell>{message.payload.title}</TableCell>
                          <TableCell>{message.payload.body}</TableCell>
                          <TableCell
                            sx={{
                              maxWidth: "200px",
                              overflow: "hidden",
                              fontSize: "13px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {message.statistics.sendCount}
                          </TableCell>
                          <TableCell
                            sx={{
                              maxWidth: "200px",
                              overflow: "hidden",
                              fontSize: "13px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {message.statistics.openCount}
                            {percent(
                              message.statistics.openCount,
                              message.statistics.sendCount
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              maxWidth: "200px",
                              overflow: "hidden",
                              fontSize: "13px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {message.statistics.clickCount}
                            {percent(
                              message.statistics.clickCount,
                              message.statistics.sendCount
                            )}
                          </TableCell>
                          <TableCell>
                            <ActionTableComp
                              onDelete={() => onDelete(message)}
                              onEdit={() => onEdit(message)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <MDBox
                    pt={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <MDTypography variant="caption" mr={1}>
                      Items per page:
                    </MDTypography>
                    <select
                      value={itemsPerPage}
                      onChange={(event) =>
                        setItemsPerPage(Number(event.target.value))
                      }
                    >
                      {itemsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </MDBox>
                  <MDBox
                    pt={3}
                    mx={2}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="caption">
                      Page {currentPage} of {Math.ceil(total / itemsPerPage)}
                    </MDTypography>
                    <Pagination
                      count={Math.ceil(total / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="secondary"
                      size="large"
                    />
                  </MDBox>
                  {isLoading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      marginBottom="3rem"
                    >
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : null}
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
