import { Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { findEmailById } from "repositories/email-repository";
import { DeleteButton } from "./delete-button.component";

export function OpenEmail({ style, data, onEdit, onDelete }) {
  const [email, setEmail] = useState(null);

  useEffect(() => {
    findEmailById(data.emailId).then((result) => setEmail(result.data));
  }, [data]);

  return (
    <button type="button" style={{ ...style, backgroundColor: "yellow" }}>
      <div>
        <Icon
          style={{
            paddingTop: "4px",
            marginRight: "4px",
          }}
        >
          mark_email_read
        </Icon>
        <span>
          Email opened "{data.emailId == null ? "Any Email" : email?.name}"
        </span>
      </div>
      <DeleteButton onEdit={onEdit} onDelete={onDelete} />
    </button>
  );
}
