import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { MDButton } from "components/MDButton";
import { useState } from "react";

const days = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
];
const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24,
];

const minutes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
  60,
];

export function WaitEditor({ data, onCancel, onSave }) {
  let rest = data.time || 0;
  const [day, setDay] = useState(Math.floor(rest / (60 * 24)));
  rest %= 60 * 24;
  const [hour, setHour] = useState(Math.floor(rest / 60));
  rest %= 60;
  const [minute, setMinute] = useState(Math.floor(rest));

  function onCreate() {
    onSave({
      time: day * 60 * 24 + hour * 60 + minute,
    });
  }

  return (
    <div>
      <div style={{ marginBottom: "1rem" }}>Select a waiting time</div>
      <FormControl sx={{ minWidth: 80 }}>
        <InputLabel id="select-day-label">Days</InputLabel>
        <Select
          value={day}
          label="day"
          labelId="select-day-label"
          onChange={(e) => setDay(e.target.value)}
          placeholder="Select an day"
        >
          {days.map((_) => (
            <MenuItem key={_} value={_}>
              {_}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ ml: 1, mr: 1, minWidth: 80 }}>
        <InputLabel id="select-hour-label">Hours</InputLabel>
        <Select
          value={hour}
          label="hour"
          labelId="select-hour-label"
          onChange={(e) => setHour(e.target.value)}
          placeholder="Select an hour"
        >
          {hours.map((_) => (
            <MenuItem key={_} value={_}>
              {_}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 80 }}>
        <InputLabel id="select-minute-label">Minutes</InputLabel>
        <Select
          value={minute}
          label="minute"
          labelId="select-minute-label"
          onChange={(e) => setMinute(e.target.value)}
          placeholder="Select an minute"
        >
          {minutes.map((_) => (
            <MenuItem key={_} value={_}>
              {_}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="white"
            fullWidth
            onClick={onCancel}
          >
            Cancel
          </MDButton>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={onCreate}
          >
            Create
          </MDButton>
        </Grid>
      </Grid>
    </div>
  );
}
