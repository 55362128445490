import { Dashboard } from "layouts/dashboard/Dashboard";
import { ContactList } from "layouts/contacts/ContactList";
import { EmailList } from "layouts/emails";
import { AutomationList } from "layouts/automations";
import SignIn from "layouts/authentication/login";
import Icon from "@mui/material/Icon";
import { IntegrationList } from "layouts/integrations";
import { SmsList } from "layouts/sms";
import { ListView } from "layouts/lists";
import { DispatchList } from "layouts/dispatch/DispatchList";
import { WebPushList } from "layouts/web-push";
import { WebpushMessageList } from "layouts/web-push-messages";
import { WebPushSubscriptionList } from "layouts/web-push-subscriptions";

export const routes = [
  {
    auth: true,
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    auth: true,
    type: "collapse",
    name: "Contacts",
    key: "contacts",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/contacts",
    component: <ContactList />,
  },
  {
    auth: true,
    type: "collapse",
    name: "Lists",
    key: "lists",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/lists",
    component: <ListView />,
  },
  {
    auth: true,
    type: "collapse",
    name: "Dispatch",
    key: "dispatches",
    icon: <Icon fontSize="small">send</Icon>,
    route: "/dispatches",
    component: <DispatchList />,
  },
  {
    auth: true,
    type: "collapse",
    name: "Emails",
    key: "emails",
    icon: <Icon fontSize="small">email</Icon>,
    route: "/emails",
    component: <EmailList />,
  },
  {
    auth: true,
    type: "collapse",
    name: "SMS",
    key: "sms",
    icon: <Icon fontSize="small">sms</Icon>,
    route: "/sms",
    component: <SmsList />,
  },
  {
    auth: true,
    type: "collapse",
    name: "Webpush",
    key: "webpushd",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/webpushd",
    subRoutes: [
      {
        auth: true,
        type: "collapse",
        name: "Subscriptions",
        key: "webpush/subscriptions",
        icon: <Icon fontSize="small">check_circle</Icon>,
        route: "/webpush/subscriptions",
        component: <WebPushSubscriptionList />,
      },
      {
        auth: true,
        type: "collapse",
        name: "Messages",
        key: "webpush/messages",
        icon: <Icon fontSize="small">message</Icon>,
        route: "/webpush/messages",
        component: <WebpushMessageList />,
      },
      {
        auth: true,
        type: "collapse",
        name: "Configuration",
        key: "webpush",
        icon: <Icon fontSize="small">settings</Icon>,
        route: "/webpush",
        component: <WebPushList />,
      },
    ],
  },
  {
    auth: true,
    type: "collapse",
    name: "Automations",
    key: "automations",
    icon: <Icon fontSize="small">autorenew</Icon>,
    route: "/automations",
    component: <AutomationList />,
  },
  {
    auth: true,
    type: "collapse",
    name: "Integrations",
    key: "integrations",
    icon: <Icon fontSize="small">api</Icon>,
    route: "/integrations",
    component: <IntegrationList />,
  },
  /* {
    auth: true,
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  }, */
  /*   {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  }, */
  /* {
    auth: true,
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  }, */
  {
    auth: false,
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/login",
    component: <SignIn />,
  },
];
