import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MDButton } from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormValidationError } from "layouts/authentication/components/form-validation-error";
import {
  createIntegration,
  findIntegrationById,
  updateIntegration,
} from "repositories/integration-repository";
import { IntegrationComponentSelector } from "./integration-component-selector";
import { ServiceType } from "../enums";

function getDefaultData(type) {
  switch (type) {
    case ServiceType.AWS_EMAIL:
      return { sender: "", accessKeyId: "", secretAccessKey: "", region: "" };
    case ServiceType.CONTELE:
      return { token: "" };
    case ServiceType.QUICK_EMAIL:
      return { token: "" };
    case ServiceType.SENDGRID:
      return { sender: "", token: "" };
    case ServiceType.SENDBLUE:
      return { sender: "", token: "" };
  }
}

function IntegrationComponent({ data, integration, onDataChange }) {
  function setDataP(property, value) {
    const newValue = { ...data, [property]: value };
    onDataChange(newValue);
  }

  function getFields() {
    switch (integration.serviceType) {
      case ServiceType.AWS_EMAIL:
        return (
          <div>
            <MDInput
              value={data.sender}
              style={{ marginTop: "1rem" }}
              onChange={(e) => setDataP("sender", e.target.value)}
              error={data.sender.length === 0}
              helperText={!data.sender.length ? "sender is required" : ""}
              label="Sender"
              fullWidth
            />
            <MDInput
              value={data.accessKeyId}
              style={{ marginTop: "1rem" }}
              onChange={(e) => setDataP("accessKeyId", e.target.value)}
              error={data.accessKeyId.length === 0}
              helperText={
                !data.accessKeyId.length ? "accessKeyId is required" : ""
              }
              label="Access Key Id"
              fullWidth
            />
            <MDInput
              value={data.secretAccessKey}
              style={{ marginTop: "1rem" }}
              onChange={(e) => setDataP("secretAccessKey", e.target.value)}
              error={data.secretAccessKey.length === 0}
              helperText={
                !data.secretAccessKey.length
                  ? "secretAccessKey is required"
                  : ""
              }
              label="Secret Access Key"
              fullWidth
            />
            <MDInput
              value={data.region}
              style={{ marginTop: "1rem" }}
              onChange={(e) => setDataP("region", e.target.value)}
              error={data.region.length === 0}
              helperText={!data.region.length ? "region is required" : ""}
              label="Region"
              fullWidth
            />
          </div>
        );
      case ServiceType.CONTELE:
        return (
          <MDInput
            value={data.token}
            onChange={(e) => setDataP("token", e.target.value)}
            error={data.token.length === 0}
            helperText={!data.token.length ? "token is required" : ""}
            label="Token"
            fullWidth
          />
        );
      case ServiceType.QUICK_EMAIL:
        return (
          <MDInput
            value={data.token}
            onChange={(e) => setDataP("token", e.target.value)}
            error={data.token.length === 0}
            helperText={!data.token.length ? "token is required" : ""}
            label="Token"
            fullWidth
          />
        );
      case ServiceType.SENDGRID:
        return (
          <div>
            <MDInput
              value={data.sender}
              style={{ marginTop: "1rem" }}
              onChange={(e) => setDataP("sender", e.target.value)}
              error={data.sender.length === 0}
              helperText={!data.sender.length ? "sender is required" : ""}
              label="Sender"
              fullWidth
            />
            <MDInput
              value={data.token}
              style={{ marginTop: "1rem" }}
              onChange={(e) => setDataP("token", e.target.value)}
              error={data.token.length === 0}
              helperText={!data.token.length ? "token is required" : ""}
              label="Token"
              fullWidth
            />
          </div>
        );
      case ServiceType.SENDBLUE:
        return (
          <div>
            <MDInput
              value={data.sender}
              style={{ marginTop: "1rem" }}
              onChange={(e) => setDataP("sender", e.target.value)}
              error={data.sender.length === 0}
              helperText={!data.sender.length ? "sender is required" : ""}
              label="Sender"
              fullWidth
            />
            <MDInput
              value={data.token}
              style={{ marginTop: "1rem" }}
              onChange={(e) => setDataP("token", e.target.value)}
              error={data.token.length === 0}
              helperText={!data.token.length ? "token is required" : ""}
              label="Token"
              fullWidth
            />
          </div>
        );
    }
  }

  return (
    <div
      style={{
        width: "100%",
        padding: "1rem",
        border: "1px solid black",
        borderRadius: "0.5rem",
      }}
    >
      <div>{integration.title}</div>
      <div>{integration.description}</div>
      {getFields()}
    </div>
  );
}

export function IntegrationCreate() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(null);
  const [openComponentSelector, setOpenComponentSelector] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [integration, setIntegration] = useState(null);
  const [data, setData] = useState(null);
  const companyId = localStorage.getItem("companyId");
  const { id } = useParams();

  useEffect(() => {
    if (id != null)
      findIntegrationById(id).then(({ data }) => {
        setName(data.name);
        setDescription(data.description);
        setData(data.data);
        setIntegration({
          type: data.type,
          serviceType: data.serviceType,
        });
      });
  }, []);

  function onCreate() {
    if (id == null) {
      createIntegration(
        name,
        description,
        companyId,
        integration.type,
        integration.serviceType,
        data
      )
        .then(() => {
          navigate("/integrations", { replace: true });
        })
        .catch((error) => {
          setValidationError(error.message);
        });
    } else {
      updateIntegration(
        id,
        name,
        description,
        integration.type,
        integration.ServiceType,
        data
      ).then(() => {
        navigate("/integrations", { replace: true });
      });
    }
  }

  function onSelect(integration) {
    setIntegration(integration);
    setData(getDefaultData(integration.serviceType));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <IntegrationComponentSelector
        open={openComponentSelector}
        setOpen={setOpenComponentSelector}
        onSelect={onSelect}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      {id == null ? 'Create New' : 'Update'} Integration
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} mx={2} mb={2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={name.length === 0}
                      helperText={!name.length ? "name is required" : ""}
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      error={description.length === 0}
                      helperText={
                        !description.length ? "description is required" : ""
                      }
                      type="description"
                      label="Description"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  {integration == null ? (
                    <div>
                      <div>Select an integration type</div>
                      <MDButton onClick={() => setOpenComponentSelector(true)}>
                        Select
                      </MDButton>
                    </div>
                  ) : (
                    <IntegrationComponent
                      integration={integration}
                      data={data}
                      onDataChange={setData}
                    />
                  )}
                  <br />
                  <FormValidationError
                    error={validationError}
                    onDismiss={() => {
                      setValidationError(null);
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="white"
                        fullWidth
                        onClick={() =>
                          navigate("/integrations", { replace: true })
                        }
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={onCreate}
                      >
                        {id == null ? 'Create' : 'Update'}
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
