import { Icon } from "@mui/material";
import { DeleteButton } from "./delete-button.component";

export function ContactCreateTrigger({ style, onDelete }) {
  return (
    <button type="button" style={style}>
      <div>
        <Icon style={{ paddingTop: "4px", marginRight: "4px" }}>person</Icon>
        <span>Contact Create Trigger</span>
        <br />
        <span style={{ fontSize: "0.8rem" }}>Contact added to platform</span>
      </div>
      <DeleteButton onDelete={onDelete} />
    </button>
  );
}
