import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { MDButton } from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormValidationError } from "layouts/authentication/components/form-validation-error";
import {
  createAutomation,
  findAutomationById,
} from "repositories/automation-repository";
import ObjectID from "bson-objectid";
import { ConditionType, StepType } from "./components/enums";
import { ComponentSelector } from "./component-selector";
import { defaultNodes } from "./defaultNodes";
import { AddNewNode } from "./components/add-new-node";
import { Block } from "./components/block.component";
import { AddNewTrigger } from "./components/add-new-trigger.component";

function Comp({ nodes, onAddNewNode, onSelectNode, onDelete }) {
  const size = 12 / nodes.length;

  return (
    <Grid container spacing={2}>
      {nodes.map((node) => (
        <Grid item xs={size} key={node._id}>
          <AddNewNode onClick={() => onAddNewNode(node)} />
          <Block
            node={node}
            onSelectNode={() => onSelectNode(node)}
            onDelete={() => onDelete(node)}
          />
          <Comp
            nodes={node.nodes}
            onAddNewNode={onAddNewNode}
            onSelectNode={onSelectNode}
            onDelete={onDelete}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function countLeafNodes(node) {
  if (node.nodes.length === 0) {
    return 1;
  }

  let count = 0;

  for (let i = 0; i < node.nodes.length; i++) {
    count += countLeafNodes(node.nodes[i]);
  }

  return count;
}

export function AutomationCreate() {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(null);
  const [openComponentSelector, setOpenComponentSelector] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nodes, setNodes] = useState(defaultNodes);
  const [editMode, setEditMode] = useState(false);

  nodes.forEach((node) => {
    node.nodes = nodes.filter((_) => node.nextNodeIds.includes(_._id));
  });

  const triggers = nodes.filter((node) => node.type === StepType.TRIGGER);
  const companyId = localStorage.getItem("companyId");

  const treeWidthCount = countLeafNodes(triggers[0]);
  const treeWidth = Math.max(100, treeWidthCount * 50);
  const { id } = useParams();

  useEffect(() => {
    if (id != null)
      findAutomationById(id).then(({ data }) => {
        setName(data.name);
        setDescription(data.description);
        setNodes(data.nodes);
      });
  }, [id, name, description, nodes]);

  function onCreate() {
    createAutomation(companyId, name, description, nodes)
      .then(() => {
        navigate("/automations", { replace: true });
      })
      .catch((error) => {
        setValidationError(error.message);
      });
  }

  function onAddNewNodeComponent(node, nodeType, componentType, data) {
    if (editMode) {
      node.data = data;
      setNodes(nodes);
      return;
    }

    if (nodeType == StepType.TRIGGER) {
      nodes.push({
        _id: new ObjectID().toHexString(),
        type: nodeType,
        component: componentType,
        data,
        nextNodeIds: [...triggers[0].nextNodeIds],
      });
      setNodes(nodes);
      return;
    }

    const parentNode = nodes.find((_) => _.nextNodeIds.includes(node._id));
    const newNode = {
      _id: new ObjectID().toHexString(),
      type: nodeType,
      component: componentType,
      data,
      nextNodeIds: [node._id],
    };

    if (
      nodeType === StepType.CONDITION &&
      componentType == ConditionType.IF_ELSE
    ) {
      const endNode = {
        _id: new ObjectID().toHexString(),
        type: StepType.END,
        component: componentType,
        data,
        nextNodeIds: [],
      };
      newNode.nextNodeIds.push(endNode._id);
      nodes.push(endNode);
    }

    const index = parentNode.nextNodeIds.findIndex((_) => _ == node._id);
    parentNode.nextNodeIds.splice(index, 1);
    parentNode.nextNodeIds.push(newNode._id);

    nodes.push(newNode);

    setNodes(nodes);
  }

  function removeNode(nodeId) {
    const nodeIndex = nodes.findIndex((_) => _._id === nodeId);

    if (nodeIndex > -1) {
      nodes.splice(nodeIndex, 1);
    }
  }

  function clearNodes() {
    nodes.forEach((node) => {
      const parentNode = nodes.find((_) => _.nextNodeIds.includes(node._id));

      if (
        parentNode == null &&
        node.type != StepType.END &&
        node.type != StepType.TRIGGER
      ) {
        removeNode(node._id);
        clearNodes();
      }
    });
  }

  function onDeleteNode(node) {
    const parentNode = nodes.find((_) => _.nextNodeIds.includes(node._id));
    const childNodeId = node.nextNodeIds[0];

    parentNode.nextNodeIds.forEach((id, i) => {
      if (id === node._id) {
        parentNode.nextNodeIds[i] = childNodeId;
      }
    });

    removeNode(node._id);
    clearNodes();

    setNodes([...nodes]);
  }

  function onDeleteTriggerNode(node) {
    removeNode(node._id);
    setNodes([...nodes]);
  }

  function onSelectNode(node) {
    setOpenComponentSelector(true);
    setSelectedNode(node);
    setEditMode(true);
  }

  function onAddNewTrigger() {
    setOpenComponentSelector(true);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ComponentSelector
        node={selectedNode}
        open={openComponentSelector}
        editMode={editMode}
        setOpen={setOpenComponentSelector}
        onSelect={onAddNewNodeComponent}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      {id == null ? 'Create New' : 'Update'} Automation
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} mx={2} mb={2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={name.length === 0}
                      helperText={!name.length ? "name is required" : ""}
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      error={description.length === 0}
                      helperText={
                        !description.length ? "description is required" : ""
                      }
                      type="description"
                      label="Description"
                      variant="standard"
                      fullWidth
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            <div style={{ width: `${treeWidth}%`, margin: "40px 0" }}>
              <p>Start this automation when one of these actions takes place</p>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  gap: "1rem",
                  textAlign: "center",
                }}
              >
                {triggers.map((node) => (
                  <Block
                    key={node._id}
                    node={node}
                    onDelete={
                      triggers.length > 1
                        ? () => onDeleteTriggerNode(node)
                        : null
                    }
                    onSelectNode={() => onSelectNode(node)}
                  />
                ))}
                <AddNewTrigger onClick={onAddNewTrigger} />
              </div>
              <Comp
                nodes={triggers[0].nodes}
                onSelectNode={onSelectNode}
                onDelete={onDeleteNode}
                onAddNewNode={(node) => {
                  setOpenComponentSelector(true);
                  setSelectedNode(node);
                  setEditMode(false);
                }}
              />
              <br />
              <br />
            </div>

            <Card>
              <MDBox pt={2} mx={2} mb={2}>
                <FormValidationError
                  error={validationError}
                  onDismiss={() => {
                    setValidationError(null);
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={6}>
                    <MDButton
                      variant="gradient"
                      color="white"
                      fullWidth
                      onClick={() =>
                        navigate("/automations", { replace: true })
                      }
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={onCreate}
                    >
                      {id == null ? 'Create' : 'Update'}
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
