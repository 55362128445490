import { Button, Icon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export function ActionTableComp({ onEdit, onDelete, children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Icon>more_vert</Icon>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {onEdit != null ? <MenuItem onClick={onEdit}>Edit</MenuItem> : null}
        {onDelete != null ? (
          <MenuItem onClick={onDelete}>Delete</MenuItem>
        ) : null}
        {children}
      </Menu>
    </div>
  );
}
