import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { MDButton } from "components/MDButton";
import { Link } from "react-router-dom";
import { Button, CircularProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";
import { deleteDispatchById, findDispatches } from "repositories/dispatch-repository";
import { ActionTableComp } from "components/ActionTableComp";
import { ViewDetailsComponent } from "./create/view-details";

function formatDate(data) {
  const day = String(data.getDate()).padStart(2, "0");
  const month = String(data.getMonth() + 1).padStart(2, "0");
  const year = String(data.getFullYear());
  const hour = String(data.getHours()).padStart(2, "0");
  const minute = String(data.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}`;
}

export function DispatchList() {
  const [total, setTotal] = useState(0);
  const [dispatches, setDispatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openComponentSelector, setOpenComponentSelector] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPageOptions = [10, 25, 50, 100, 500, 1000];

  function fetchData(pageIndex) {
    setIsLoading(true);
    findDispatches({}, (pageIndex - 1) * itemsPerPage, itemsPerPage)
      .then((result) => {
        const [data, count] = result.data;
        setDispatches(data);
        setTotal(count);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, itemsPerPage]);

  function onDelete(dispatch) {
    if (dispatch.status === "SCHEDULED") {
      deleteDispatchById(dispatch._id).then(() => {
        setDispatches(dispatches.filter((_) => _._id !== dispatch._id));
      });
    }
  }

  function handlePageChange(event, value) {
    setCurrentPage(value);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {openComponentSelector ? (
        <ViewDetailsComponent
          dispatch={openComponentSelector}
          handleClose={() => setOpenComponentSelector(null)}
        />
      ) : null}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Dispatches
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Link to="/dispatches/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;create new
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <TableContainer sx={{ boxShadow: "none" }}>
                  <Table>
                    <MDBox component="thead">
                      <TableRow>
                        <DataTableHeadCell>Description</DataTableHeadCell>
                        <DataTableHeadCell>Status</DataTableHeadCell>
                        <DataTableHeadCell>Scheduled at</DataTableHeadCell>
                        <DataTableHeadCell>Execution Details (Total/Sent/Errors)</DataTableHeadCell>
                        <DataTableHeadCell>Modified at</DataTableHeadCell>
                        <DataTableHeadCell>Action</DataTableHeadCell>
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {dispatches.map((dispatch) => (
                        <TableRow key={dispatch._id}>
                          <TableCell>{dispatch.description}</TableCell>
                          <TableCell>
                            <MDBox ml={-1}>
                              <MDBadge
                                badgeContent={dispatch.status}
                                color="success"
                                variant="gradient"
                                size="sm"
                              />
                            </MDBox>
                          </TableCell>
                          <TableCell>
                            <MDTypography
                              component="a"
                              href="#"
                              variant="caption"
                              color="text"
                              fontWeight="medium"
                            >
                              {dispatch.runAt ? formatDate(new Date(dispatch.runAt)) : ""}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <div>
                              {dispatch.executionDetails.totalContacts}/
                              {dispatch.executionDetails.sentCount}/
                              {dispatch.executionDetails.errorCount}
                            </div>
                          </TableCell>
                          <TableCell>
                            <MDTypography
                              component="a"
                              href="#"
                              variant="caption"
                              color="text"
                              fontWeight="medium"
                            >
                              {dispatch.updatedAt.split("T")[0]}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: 'flex' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenComponentSelector(dispatch)}
                                style={{ marginLeft: '10px', color: 'white' }}
                              >
                                View
                              </Button>
                              <ActionTableComp onDelete={() => onDelete(dispatch)} />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>
              <MDBox
                pt={3}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <MDTypography variant="caption" mr={1}>
                  Items per page:
                </MDTypography>
                <select
                  value={itemsPerPage}
                  onChange={(event) =>
                    setItemsPerPage(Number(event.target.value))
                  }
                >
                  {itemsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </MDBox>
              <MDBox
                pt={3}
                mx={2}
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="caption">
                  Page {currentPage} of {Math.ceil(total / itemsPerPage)}
                </MDTypography>
                <Pagination
                  count={Math.ceil(total / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="secondary"
                  size="large"
                />
              </MDBox>
              {isLoading ? (
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  marginBottom="3rem"
                >
                  <CircularProgress color="inherit" />
                </MDBox>
              ) : null}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
