import { Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { findEmailById } from "repositories/email-repository";
import { findIntegrationById } from "repositories/integration-repository";
import { DeleteButton } from "./delete-button.component";

export function SendEmail({ style, data, onEdit, onDelete }) {
  const [email, setEmail] = useState(null);
  const [integration, setIntegration] = useState(null);

  useEffect(() => {
    findEmailById(data.emailId).then((result) => setEmail(result.data));
    findIntegrationById(data.integrationId).then((result) =>
      setIntegration(result.data)
    );
  }, [data]);

  return (
    <div style={style}>
      <div>
        <Icon style={{ paddingTop: "4px", marginRight: "4px" }}>email</Icon>
        Send Email "{email?.name}" by "{integration?.name}"
      </div>
      <DeleteButton onEdit={onEdit} onDelete={onDelete} />
    </div>
  );
}
