import { Grid } from "@mui/material";
import { MDButton } from "components/MDButton";
import { useState } from "react";
import { Operator, SegmentType } from "../components/enums";

const sources = [
  {
    name: "contact",
    label: "contact",
    properties: [
      { name: "full name", value: "fullname" },
      { name: "firstname", value: "firstname" },
      { name: "email", value: "email" },
      { name: "phone", value: "phone" },
      { name: "created at", value: "createdAt" },
      { name: "status", value: "status" },
    ],
  },
];

const operations = Object.keys(Operator).map((key) => ({
  name: key.replace(/_/g, " "),
  value: Operator[key],
}));

function SegmentComponent({ segment, onChange }) {
  const sourceIndex = sources.findIndex(
    (_) => _.value === segment.operation.source
  );
  const source = sources[sourceIndex];
  const propertyIndex = source.properties.findIndex(
    (_) => _.value === segment.operation.property
  );

  return (
    <div
      style={{
        border: "1px solid",
        borderRadius: "0.5rem",
        padding: "0.8rem",
        gap: "0.5rem",
        display: "flex",
      }}
    >
      <select
        value={sourceIndex}
        onChange={(e) => {
          segment.source = sources[e.target.value].name;
          onChange(segment);
        }}
        placeholder="Select a source"
      >
        {sources.map((source, i) => (
          <option key={source.name} value={i} label={source.name}>
            {source.label}
          </option>
        ))}
      </select>
      <select
        value={propertyIndex}
        onChange={(e) => {
          const property = source.properties[e.target.value].value;
          segment.operation.property = property;
          onChange(segment);
        }}
        placeholder="Select a property"
      >
        {source.properties.map((property, i) => (
          <option key={property.value} value={i}>
            {property.name}
          </option>
        ))}
      </select>
      <select
        value={segment.operation.operator}
        onChange={(e) => {
          const operator = operations[e.target.value].value;
          segment.operation.operator = operator;
          onChange(segment);
        }}
        placeholder="Select an operation"
      >
        {operations.map((operation, i) => (
          <option key={operation.value} value={i}>
            {operation.name}
          </option>
        ))}
      </select>
      <input
        value={segment.operation.value}
        placeholder="value"
        onChange={(e) => {
          segment.operation.value = e.target.value;
          onChange(segment);
        }}
      />
    </div>
  );
}

export function WaitUntilEditor({ data, onCancel, onSave }) {
  const [segments, setSegments] = useState(
    data.segments || [
      {
        segmentType: SegmentType.AND,
        operation: {
          source: sources[0].value,
          property: sources[0].properties[0].value,
          value: "",
          operator: Operator.IS,
        },
      },
    ]
  );

  function onCreate() {
    onSave({
      segments,
    });
  }

  function onAddNewSegment() {
    setSegments([
      ...segments,
      {
        segmentType: SegmentType.AND,
        operation: {
          source: sources[0].value,
          property: sources[0].properties[0].value,
          value: "",
          operator: Operator.IS,
        },
      },
    ]);
  }

  return (
    <div>
      <div>Select a list</div>
      {segments.map((segment, i) => {
        const segmentType =
          segment.segmentType == SegmentType.AND ? "AND" : "OR";

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <SegmentComponent
              segment={segment}
              onChange={(data) => {
                const list = [...segments];
                list[i] = data;
                setSegments(list);
              }}
            />
            {i < segments.length - 1 ? (
              <div
                style={{
                  borderRadius: "0.6rem",
                  backgroundColor: "blue",
                  color: "white",
                  margin: "auto",
                  padding: "0.2rem 1rem",
                  width: "fit-content",
                  fontSize: "0.6rem",
                  marginTop: "-10px",
                  marginBottom: "-10px",
                }}
              >
                {segmentType}
              </div>
            ) : null}
          </div>
        );
      })}
      <MDButton
        variant="gradient"
        color="white"
        fullWidth
        style={{ margin: "0.5rem 0" }}
        onClick={onAddNewSegment}
      >
        Add New Condition
      </MDButton>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="white"
            fullWidth
            onClick={onCancel}
          >
            Cancel
          </MDButton>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={onCreate}
          >
            Create
          </MDButton>
        </Grid>
      </Grid>
    </div>
  );
}
