import { Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { findIntegrationById } from "repositories/integration-repository";
import { findSmsById } from "repositories/sms-repository";
import { DeleteButton } from "./delete-button.component";

export function SendSms({ style, data, onEdit, onDelete }) {
  const [sms, setSms] = useState(null);
  const [integration, setIntegration] = useState(null);

  useEffect(() => {
    findSmsById(data.smsId).then((result) => setSms(result.data));
    findIntegrationById(data.integrationId).then((result) =>
      setIntegration(result.data)
    );
  }, [data]);

  return (
    <button type="button" style={style}>
      <div>
        <Icon style={{ paddingTop: "4px", marginRight: "4px" }}>message</Icon>
        Send SMS "{sms?.name}" by "{integration?.name}"
      </div>
      <DeleteButton onEdit={onEdit} onDelete={onDelete} />
    </button>
  );
}
