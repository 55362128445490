import { Icon } from "@mui/material";
import { Operator, SegmentType } from "./enums";
import { DeleteButton } from "./delete-button.component";

export function WaitUntilComponent({ style, data, onClick, onDelete }) {
  return (
    <button type="button" style={style} onClick={onClick}>
      <Icon
        style={{
          paddingTop: "4px",
          marginRight: "4px",
        }}
      >
        access_time
      </Icon>
      <span>Does the contact match the following conditions?</span>
      <span style={{ fontSize: "0.8rem" }}>
        {data.segments.map((segment, i) => {
          const operator = Object.keys(Operator).find(
            (key) => Operator[key] === segment.operation.operator
          );

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>
              <div>
                {segment.operation.source} {segment.operation.property}{" "}
                {operator.replace(/_/g, " ")} "{segment.operation.value}"
              </div>
              {i < data.segments.length - 1 ? (
                <div style={{ fontWeight: "bold" }}>
                  {segment.segmentType == SegmentType.AND ? "AND" : "OR"}
                </div>
              ) : null}
            </div>
          );
        })}
      </span>
      <DeleteButton onDelete={onDelete} />
    </button>
  );
}
