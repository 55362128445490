import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { MDButton } from "components/MDButton";
import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { DashboardNavbar } from "examples/Navbars/DashboardNavbar/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { deleteEmail, findEmails } from "repositories/email-repository";
import { ActionTableComp } from "components/ActionTableComp";

function Per({ value, div }) {
  if (value == null || div == null || div == 0) {
    return <div>--</div>;
  }

  return <div>{((value / div) * 100).toFixed(1).replace(".0", "")}%</div>;
}

export function EmailList() {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    findEmails()
      .then((result) => {
        setEmails(result.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  function onEdit(email) {
    window.open(`/emails/${email._id}`, "_blank");
  }

  function onDelete(email) {
    deleteEmail(email._id).then(() => {
      setEmails(emails.filter((_) => _._id != email._id));
    });
  }

  const columns = [
    { Header: "name", accessor: "name", width: "45%", align: "left" },
    {
      Header: "description",
      accessor: "description",
      width: "45%",
      align: "left",
    },
    {
      Header: "statistics (send/open/click)",
      accessor: "statistics",
      width: "45%",
      align: "left",
    },
    {
      Header: "open rate",
      accessor: "open",
      width: "45%",
      align: "left",
    },
    {
      Header: "click rate",
      accessor: "click",
      width: "45%",
      align: "left",
    },
    { Header: "modified at", accessor: "updatedAt", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = emails.map((email) => ({
    name: <div>{email.name}</div>,
    description: <div>{email.description}</div>,
    statistics: (
      <div>
        {email.statistics.sendCount}/{email.statistics.openCount}/
        {email.statistics.clickCount}
      </div>
    ),
    open: (
      <Per
        value={email.statistics.openCount}
        div={email.statistics.sendCount}
      />
    ),
    click: (
      <Per
        value={email.statistics.clickCount}
        div={email.statistics.sendCount}
      />
    ),
    updatedAt: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {email.updatedAt.split("T")[0]}
      </MDTypography>
    ),
    action: (
      <ActionTableComp
        onDelete={() => onDelete(email)}
        onEdit={() => onEdit(email)}
      />
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Emails
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Link to="/emails/create">
                      <MDButton variant="gradient" color="dark">
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                        &nbsp;add new
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isLoading={isLoading}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
