import { Button, Icon, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { ViewWebpushMessageDetailsComponent } from "layouts/web-push-messages/create/view-details";
import { useEffect, useState } from "react";
import { findWebpushMessageById } from "repositories/webpush-message-repository";

export function ViewDetailsComponent({ dispatch, handleClose }) {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    findWebpushMessageById(dispatch.parameters.webpushMessageId).then(message => {
      setMessage(message.data);
    })
  });

  return (
    <Modal
      open={dispatch != null}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          minWidth: "800px",
          borderRadius: "0.8rem",
          boxShadow: 24,
        }}
      >
        <div
          id="modal-modal-title"
          style={{
            padding: "0.8rem",
          }}
        >
          Details
          <Button onClick={handleClose}>
            <Icon>close</Icon>
          </Button>
        </div>
        <div id="modal-modal-description">
          <ViewWebpushMessageDetailsComponent message={message}></ViewWebpushMessageDetailsComponent>
        </div>
      </Box>
    </Modal>
  );
}
