import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "webpush/subscriptions";

export const findWebpushSubscriptions = cache(
  "findWebpushSubscriptions",
  (filter, skip, limit) =>
    client.get(path, {
      params: {
        filter: JSON.stringify({
          ...filter,
          companyId: localStorage.getItem("companyId"),
        }),
        skip,
        limit,
      },
    })
);

export function deleteWebpushSubscription(id) {
  return client.delete(`${path}/${id}`);
}

export const findWebpushSubscriptionById = cache("WebpushSubscription", (id) =>
  client.get(`${path}/${id}`)
);
