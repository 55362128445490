import { cache } from "layouts/services/cache-service";
import { client } from "./client";

const path = "emails";

export async function createEmail(
  companyId,
  name,
  description,
  subject,
  from,
  html
) {
  return client.post(path, {
    companyId,
    name,
    description,
    subject,
    from,
    html,
  });
}

export async function updateEmail(id, name, description, subject, from, html) {
  return client.patch(`${path}/${id}`, {
    name,
    description,
    subject,
    from,
    html,
  });
}

export const findEmails = cache("findEmails", (name) =>
  client.get(path, {
    params: {
      companyId: localStorage.getItem("companyId"),
      name,
    },
  })
);

export function deleteEmail(emailId) {
  return client.delete(`${path}/${emailId}`);
}

export const findEmailById = cache("findEmailById", (emailId) =>
  client.get(`${path}/${emailId}`)
);
