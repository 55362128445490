import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { MDButton } from "components/MDButton";
import { useEffect, useState } from "react";
import { findUsers } from "repositories/user-repository";

export function UserList({ shadow }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    findUsers().then((result) => {
      setUsers(result.data);
    });
  }, []);

  const renderProfiles = users.map((user) => (
    <MDBox
      key={user._id}
      component="li"
      display="flex"
      alignItems="center"
      py={1}
      mb={1}
    >
      <MDBox mr={2}>
        <MDAvatar src={user.image} alt="something here" shadow="md" />
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <MDTypography variant="button" fontWeight="medium">
          {user.name}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {user.email}
        </MDTypography>
      </MDBox>
      <MDBox ml="auto">
        <MDButton variant="text" color="info">
          Delete
        </MDButton>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Users
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}
UserList.defaultProps = {
  shadow: true,
};
UserList.propTypes = {
  shadow: PropTypes.bool,
};
